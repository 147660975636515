import React, { useState, useEffect } from 'react';
import { CreditCard, Calendar, AlertTriangle } from 'lucide-react';
import { Button } from './ui/button';
import { createPortalSession, getSubscriptionDetails } from '../lib/stripe';

export const SubscriptionManager = () => {
  const [subscription, setSubscription] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadSubscription();
  }, []);

  const loadSubscription = async () => {
    try {
      setLoading(true);
      const data = await getSubscriptionDetails();
      setSubscription(data);
      setError(null);
    } catch (err) {
      console.error('Error loading subscription:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleManageSubscription = async () => {
    try {
      const result = await createPortalSession();
      
      if (result.error) {
        setError(result.error);
        return;
      }

      if (result.url) {
        window.location.href = result.url;
      }
    } catch (err) {
      console.error('Error managing subscription:', err);
      setError('Failed to open subscription portal');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      {error && (
        <div className="mb-4 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertTriangle className="h-5 w-5 mr-2" />
          {error}
        </div>
      )}

      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900">Subscription Details</h2>
        <Button onClick={handleManageSubscription}>
          {subscription ? 'Manage Subscription' : 'View Plans'}
        </Button>
      </div>

      {subscription ? (
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center">
                <CreditCard className="h-5 w-5 text-blue-500 mr-2" />
                <div>
                  <p className="text-sm text-gray-500">Plan</p>
                  <p className="font-medium text-gray-900">
                    {subscription.plan_id?.replace('price_', '').toUpperCase()}
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center">
                <Calendar className="h-5 w-5 text-blue-500 mr-2" />
                <div>
                  <p className="text-sm text-gray-500">Next Payment</p>
                  <p className="font-medium text-gray-900">
                    {new Date(subscription.current_period_end).toLocaleDateString()}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gray-50 rounded-lg p-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-500">Status</p>
                <p className={`font-medium ${
                  subscription.status === 'active' ? 'text-green-600' :
                  subscription.status === 'trialing' ? 'text-blue-600' :
                  'text-red-600'
                }`}>
                  {subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1)}
                </p>
              </div>
              {subscription.cancel_at_period_end && (
                <div className="bg-yellow-50 text-yellow-800 px-3 py-1 rounded-full text-sm">
                  Cancels at period end
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center py-8">
          <p className="text-gray-500">No active subscription found.</p>
          <Button onClick={handleManageSubscription} className="mt-4">
            View Plans
          </Button>
        </div>
      )}
    </div>
  );
};