import { supabase } from './supabase';
import type { Website } from './types';
import { formatUrl } from './utils';

export async function runSEOAudit(websiteId: string) {
  try {
    // First update website status to pending
    const { error: updateError } = await supabase
      .from('websites')
      .update({ status: 'pending' })
      .eq('id', websiteId);

    if (updateError) throw updateError;

    // Run the audit
    const { data, error } = await supabase.functions.invoke('run-seo-audit', {
      body: { websiteId },
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (error) {
      console.error('Function error:', error);
      await supabase
        .from('websites')
        .update({ 
          status: 'error',
          last_audit_at: new Date().toISOString()
        })
        .eq('id', websiteId);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error running SEO audit:', error);
    await supabase
      .from('websites')
      .update({ 
        status: 'error',
        last_audit_at: new Date().toISOString()
      })
      .eq('id', websiteId);
    throw error;
  }
}

export async function addWebsite(url: string, userId: string): Promise<Website> {
  try {
    // Check subscription status first
    const { data: subscription } = await supabase
      .from('subscriptions')
      .select('status, plan_id')
      .eq('user_id', userId)
      .maybeSingle();

    // Get existing websites count
    const { data: websites, error: countError } = await supabase
      .from('websites')
      .select('id')
      .eq('user_id', userId);

    if (countError) throw countError;

    // Check website limits based on plan
    const websiteCount = websites?.length || 0;
    const websiteLimit = subscription?.plan_id === 'price_basic' ? 3 
      : subscription?.plan_id === 'price_pro' ? 10 
      : subscription?.plan_id === 'price_enterprise' ? Infinity 
      : 1; // Free plan

    if (websiteCount >= websiteLimit) {
      throw new Error(`You've reached your plan's limit of ${websiteLimit} website${websiteLimit === 1 ? '' : 's'}. Please upgrade to add more.`);
    }

    // Format and validate URL
    const formattedUrl = formatUrl(url);
    if (!formattedUrl) {
      throw new Error('Please enter a valid URL');
    }

    // Check if URL already exists for this user
    const { data: existingWebsite } = await supabase
      .from('websites')
      .select('id')
      .eq('user_id', userId)
      .eq('url', formattedUrl)
      .single();

    if (existingWebsite) {
      throw new Error('This website has already been added to your account');
    }

    // Add the website
    const { data, error } = await supabase
      .from('websites')
      .insert([{ 
        url: formattedUrl, 
        user_id: userId,
        status: 'pending'
      }])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error adding website:', error);
    throw error;
  }
}

export async function getWebsites(userId: string): Promise<Website[]> {
  try {
    const { data, error } = await supabase
      .from('websites')
      .select(`
        *,
        seo_audits (*)
      `)
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error getting websites:', error);
    throw error;
  }
}

export async function getSubscriptionStatus(userId: string) {
  try {
    const { data, error } = await supabase
      .from('subscriptions')
      .select('*')
      .eq('user_id', userId)
      .maybeSingle();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error getting subscription:', error);
    return null;
  }
}

export async function getDashboardStats(userId: string) {
  try {
    const { data, error } = await supabase
      .rpc('get_dashboard_stats');

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error getting dashboard stats:', error);
    throw error;
  }
}