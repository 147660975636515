import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Link as LinkIcon, Globe, Share2, AlertTriangle, BarChart2 } from 'lucide-react';
import { Button } from '../components/ui/button';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar
} from 'recharts';

interface BacklinkData {
  domain: string;
  url: string;
  authority: number;
  anchor: string;
  firstSeen: string;
}

interface SocialMetrics {
  platform: string;
  shares: number;
  engagement: number;
  reach: number;
}

const OffPageSEO = () => {
  const navigate = useNavigate();
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [backlinks, setBacklinks] = useState<BacklinkData[]>([]);
  const [socialMetrics, setSocialMetrics] = useState<SocialMetrics[]>([]);

  const analyzeUrl = async () => {
    try {
      setLoading(true);
      setError(null);

      // Validate URL format
      let validUrl: string;
      try {
        const urlObj = new URL(url);
        validUrl = urlObj.toString();
      } catch {
        throw new Error('Please enter a valid URL');
      }

      // TODO: Implement off-page SEO analysis
      // This will be connected to the backend service later
      setBacklinks([]);
      setSocialMetrics([]);
    } catch (err) {
      console.error('Error analyzing URL:', err);
      setError(err instanceof Error ? err.message : 'Failed to analyze URL');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <div>
          <Button
            variant="ghost"
            onClick={() => navigate('/dashboard')}
            className="mb-4"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Dashboard
          </Button>
          <h1 className="text-2xl font-bold text-gray-900">Off-Page SEO Analysis</h1>
          <p className="text-gray-500 mt-2">
            Analyze your website's backlinks, social signals, and domain authority
          </p>
        </div>
      </div>

      {/* URL Input */}
      <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
        <div className="max-w-3xl mx-auto">
          <div className="flex gap-4">
            <input
              type="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter website URL (e.g., https://example.com)"
              className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <Button
              onClick={analyzeUrl}
              disabled={loading || !url}
              className="flex items-center"
            >
              {loading ? (
                <>
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                  Analyzing...
                </>
              ) : (
                <>
                  <Globe className="h-4 w-4 mr-2" />
                  Analyze
                </>
              )}
            </Button>
          </div>

          {error && (
            <div className="mt-4 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
              <AlertTriangle className="h-5 w-5 mr-2" />
              {error}
            </div>
          )}
        </div>
      </div>

      {/* Analysis Sections */}
      <div className="space-y-8">
        {/* Domain Overview */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center mb-6">
            <Globe className="h-6 w-6 text-blue-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-900">Domain Overview</h2>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <BarChart2 className="h-5 w-5 text-blue-500 mr-2" />
                  <span className="text-sm text-gray-700">Domain Authority</span>
                </div>
                <span className="text-lg font-semibold">N/A</span>
              </div>
            </div>
            
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <LinkIcon className="h-5 w-5 text-green-500 mr-2" />
                  <span className="text-sm text-gray-700">Total Backlinks</span>
                </div>
                <span className="text-lg font-semibold">N/A</span>
              </div>
            </div>
            
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Share2 className="h-5 w-5 text-purple-500 mr-2" />
                  <span className="text-sm text-gray-700">Social Shares</span>
                </div>
                <span className="text-lg font-semibold">N/A</span>
              </div>
            </div>
          </div>
        </div>

        {/* Backlink Analysis */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center mb-6">
            <LinkIcon className="h-6 w-6 text-green-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-900">Backlink Analysis</h2>
          </div>
          
          <div className="h-80 mb-6">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={[]}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="backlinks" stroke="#3B82F6" />
                <Line type="monotone" dataKey="domains" stroke="#10B981" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Domain
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Authority
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Anchor Text
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    First Seen
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" colSpan={4}>
                    Enter a URL above to analyze backlinks
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Social Signals */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center mb-6">
            <Share2 className="h-6 w-6 text-purple-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-900">Social Signals</h2>
          </div>
          
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={[]}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="platform" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="shares" fill="#3B82F6" name="Shares" />
                <Bar dataKey="engagement" fill="#10B981" name="Engagement" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Competitor Comparison */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center mb-6">
            <BarChart2 className="h-6 w-6 text-orange-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-900">Competitor Comparison</h2>
          </div>
          
          <p className="text-gray-500">
            Enter a URL above to compare your website with competitors in terms of backlinks,
            domain authority, and social signals.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OffPageSEO;