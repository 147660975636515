import React from 'react';
import { Link } from 'react-router-dom';
import { 
  ArrowRight, Search, BarChart2, Users, Globe, Zap, Shield, Star,
  Target, Award, CheckCircle, TrendingUp, Settings, RefreshCw, 
  Smartphone, Code, Lock, Eye
} from 'lucide-react';
import { Button } from '../components/ui/button';

const Home = () => {
  return (
    <div className="relative">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-600 to-indigo-700 overflow-hidden">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
            alt="Background"
            className="w-full h-full object-cover opacity-10"
          />
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="pt-20 pb-24 lg:pt-40 lg:pb-48">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
                <span className="block">Boost your website's</span>
                <span className="block text-blue-200">SEO performance</span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-blue-100 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Get expert SEO analysis and recommendations for your website. Our AI-powered platform helps you improve your search engine rankings and drive more organic traffic.
              </p>
              <div className="mt-10 flex flex-col sm:flex-row justify-center gap-4 px-4 sm:px-0">
                <Link to="/register" className="w-full sm:w-auto">
                  <Button className="w-full sm:w-auto px-8 py-3 text-lg bg-white text-blue-600 hover:bg-blue-50">
                    Get started free
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </Button>
                </Link>
                <Link to="/pricing" className="w-full sm:w-auto">
                  <Button variant="outline" className="w-full sm:w-auto px-8 py-3 text-lg text-white border-white hover:bg-white/10">
                    View pricing
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <div className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              How SeoExpert Works
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Simple steps to improve your website's SEO performance
            </p>
          </div>

          <div className="mt-20 grid grid-cols-1 gap-8 md:grid-cols-3">
            {[
              {
                icon: Globe,
                title: "Add Your Website",
                description: "Simply enter your website URL and let our advanced crawlers analyze your content."
              },
              {
                icon: Search,
                title: "Get Detailed Analysis",
                description: "Receive comprehensive SEO audit with actionable insights and recommendations."
              },
              {
                icon: TrendingUp,
                title: "Track & Improve",
                description: "Monitor your progress and implement suggestions to boost your rankings."
              }
            ].map((step, index) => {
              const Icon = step.icon;
              return (
                <div key={index} className="relative text-center">
                  <div className="flex items-center justify-center h-16 w-16 rounded-full bg-blue-100 mx-auto">
                    <Icon className="h-8 w-8 text-blue-600" />
                  </div>
                  <h3 className="mt-6 text-xl font-bold text-gray-900">{step.title}</h3>
                  <p className="mt-2 text-base text-gray-500">{step.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="bg-gray-50 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">
              Comprehensive SEO Analysis
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Everything you need to optimize your website and rank higher
            </p>
          </div>

          <div className="mt-16 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            {[
              {
                icon: Code,
                title: "Technical SEO",
                features: [
                  "Site structure analysis",
                  "Mobile optimization",
                  "Page speed insights",
                  "XML sitemap check"
                ]
              },
              {
                icon: Eye,
                title: "On-Page SEO",
                features: [
                  "Content optimization",
                  "Meta tags analysis",
                  "Keyword density",
                  "Header structure"
                ]
              },
              {
                icon: Link,
                title: "Link Analysis",
                features: [
                  "Backlink profile",
                  "Internal linking",
                  "Broken link check",
                  "Anchor text analysis"
                ]
              },
              {
                icon: Smartphone,
                title: "Mobile SEO",
                features: [
                  "Mobile-first indexing",
                  "Responsive design",
                  "Mobile usability",
                  "AMP validation"
                ]
              },
              {
                icon: Lock,
                title: "Security",
                features: [
                  "SSL certificate check",
                  "Security headers",
                  "Malware scanning",
                  "Safe browsing status"
                ]
              },
              {
                icon: Settings,
                title: "Advanced Tools",
                features: [
                  "Competitor analysis",
                  "Rank tracking",
                  "Custom reports",
                  "API access"
                ]
              }
            ].map((feature, index) => {
              const Icon = feature.icon;
              return (
                <div key={index} className="bg-white rounded-lg shadow-lg p-8">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <Icon className="h-6 w-6" />
                  </div>
                  <h3 className="mt-6 text-lg font-medium text-gray-900">{feature.title}</h3>
                  <ul className="mt-4 space-y-3">
                    {feature.features.map((item, i) => (
                      <li key={i} className="flex items-center text-gray-500">
                        <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* AI Features Section */}
      <div className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div>
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                AI-Powered SEO Intelligence
              </h2>
              <p className="mt-4 text-lg text-gray-500">
                Our advanced AI algorithms analyze your website and provide smart recommendations for improvement.
              </p>
              <div className="mt-8 space-y-4">
                {[
                  {
                    title: "Smart Content Analysis",
                    description: "AI-driven content optimization suggestions"
                  },
                  {
                    title: "Predictive Rankings",
                    description: "Machine learning based ranking predictions"
                  },
                  {
                    title: "Automated Audits",
                    description: "Regular automated website health checks"
                  },
                  {
                    title: "Competitor Intelligence",
                    description: "AI-powered competitor strategy insights"
                  }
                ].map((feature, index) => (
                  <div key={index} className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <Zap className="h-6 w-6" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h4 className="text-lg font-medium text-gray-900">{feature.title}</h4>
                      <p className="mt-2 text-base text-gray-500">{feature.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-10 lg:mt-0">
              <img
                className="rounded-lg shadow-xl"
                src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                alt="AI Analytics Dashboard"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="bg-gray-50 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">
              Trusted by Growing Businesses
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              See what our customers have to say about SeoExpert
            </p>
          </div>

          <div className="mt-16 grid grid-cols-1 gap-8 md:grid-cols-3">
            {[
              {
                quote: "SeoExpert helped us increase our organic traffic by 200% in just 3 months.",
                author: "Sarah Johnson",
                role: "Marketing Director",
                company: "TechStart Inc."
              },
              {
                quote: "The AI-powered recommendations are incredibly accurate and actionable.",
                author: "Michael Chen",
                role: "SEO Specialist",
                company: "Digital Growth"
              },
              {
                quote: "Best SEO tool we've used. The ROI has been incredible.",
                author: "Emma Davis",
                role: "CEO",
                company: "WebPro Solutions"
              }
            ].map((testimonial, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg p-8">
                <div className="flex items-center mb-4">
                  <Star className="h-5 w-5 text-yellow-400" />
                  <Star className="h-5 w-5 text-yellow-400" />
                  <Star className="h-5 w-5 text-yellow-400" />
                  <Star className="h-5 w-5 text-yellow-400" />
                  <Star className="h-5 w-5 text-yellow-400" />
                </div>
                <blockquote className="text-gray-700">{testimonial.quote}</blockquote>
                <div className="mt-4">
                  <p className="font-medium text-gray-900">{testimonial.author}</p>
                  <p className="text-gray-500">{testimonial.role}</p>
                  <p className="text-gray-500">{testimonial.company}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-blue-600">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="lg:flex lg:items-center lg:justify-between">
            <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
              <span className="block">Ready to improve your SEO?</span>
              <span className="block text-blue-200">Start your free trial today.</span>
            </h2>
            <div className="mt-8 flex flex-col sm:flex-row gap-4 lg:mt-0 lg:flex-shrink-0">
              <Link to="/register" className="w-full sm:w-auto">
                <Button className="w-full sm:w-auto px-8 py-3 text-lg bg-white text-blue-600 hover:bg-blue-50">
                  Get started free
                  <ArrowRight className="ml-2 h-5 w-5" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;