import React, { useState, useEffect } from 'react';
import { Plus, Globe, AlertTriangle, Settings, Users, DollarSign, TrendingUp, ArrowRight, Search, FileText, Link as LinkIcon, Users as UsersIcon, BarChart2, Zap } from 'lucide-react';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { useAuthStore } from '../lib/auth-store';
import { testConnection, checkDatabaseConnection } from '../lib/api';
import { addWebsite, getWebsites, getSubscriptionStatus } from '../lib/seo';
import type { Website } from '../lib/types';
import { Link, useNavigate } from 'react-router-dom';
import { SubscriptionManager } from '../components/SubscriptionManager';

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [websites, setWebsites] = useState<Website[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [connectionStatus, setConnectionStatus] = useState<'checking' | 'connected' | 'error'>('checking');
  const [newWebsiteUrl, setNewWebsiteUrl] = useState('');
  const [showAddWebsite, setShowAddWebsite] = useState(false);
  const [subscription, setSubscription] = useState<any>(null);

  useEffect(() => {
    if (user) {
      checkConnections();
      loadDashboardData();
    }
  }, [user]);

  const checkConnections = async () => {
    try {
      setConnectionStatus('checking');
      setError(null);

      // First check database connection
      await checkDatabaseConnection();
      
      // Then test edge function connection
      await testConnection();
      
      setConnectionStatus('connected');
    } catch (err) {
      console.error('Connection error:', err);
      setConnectionStatus('error');
      setError(err instanceof Error ? err.message : 'Failed to connect to service');
    }
  };

  const loadDashboardData = async () => {
    try {
      setLoading(true);
      if (!user?.id) return;
      
      const [websitesData, subscriptionData] = await Promise.all([
        getWebsites(user.id),
        getSubscriptionStatus(user.id)
      ]);

      setWebsites(websitesData);
      setSubscription(subscriptionData);
      setError(null);
    } catch (err) {
      console.error('Error loading dashboard:', err);
      setError('Failed to load dashboard data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddWebsite = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.id || !newWebsiteUrl.trim()) return;

    try {
      setLoading(true);
      const website = await addWebsite(newWebsiteUrl, user.id);
      setWebsites([website, ...websites]);
      setNewWebsiteUrl('');
      setShowAddWebsite(false);
      setError(null);
    } catch (err) {
      console.error('Error adding website:', err);
      setError(err instanceof Error ? err.message : 'Failed to add website. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const getWebsiteLimit = () => {
    if (!subscription) return 1; // Free tier
    switch (subscription.plan_id) {
      case 'price_basic': return 3;
      case 'price_pro': return 10;
      case 'price_enterprise': return Infinity;
      default: return 1;
    }
  };

  const websiteLimit = getWebsiteLimit();
  const canAddWebsite = websites.length < websiteLimit;

  const analysisModules = [
    {
      title: "Page Speed Insights",
      icon: Zap,
      description: "Analyze your website's loading speed and performance metrics",
      path: "/page-speed",
      color: "blue"
    },
    {
      title: "On-Page SEO",
      icon: Search,
      description: "Optimize your content, meta tags, and HTML structure",
      path: "/on-page-seo",
      color: "green"
    },
    {
      title: "Off-Page SEO",
      icon: LinkIcon,
      description: "Track backlinks, domain authority, and external signals",
      path: "/off-page-seo",
      color: "purple"
    },
    {
      title: "Keyword Analysis",
      icon: FileText,
      description: "Research and track your target keywords' performance",
      path: "/keywords",
      color: "orange"
    },
    {
      title: "Content Management",
      icon: BarChart2,
      description: "Analyze and optimize your content strategy",
      path: "/content",
      color: "indigo"
    },
    {
      title: "Competitor Analysis",
      icon: UsersIcon,
      description: "Monitor and compare your competitors' performance",
      path: "/competitors",
      color: "pink"
    }
  ];

  if (connectionStatus === 'checking') {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Checking connection...</p>
        </div>
      </div>
    );
  }

  if (connectionStatus === 'error') {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <AlertTriangle className="h-12 w-12 text-red-500 mx-auto" />
          <h2 className="mt-4 text-xl font-semibold text-gray-900">Connection Error</h2>
          <p className="mt-2 text-gray-600">Unable to connect to the service.</p>
          <Button onClick={checkConnections} className="mt-4">
            Try Again
          </Button>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {error && (
        <div className="mb-4 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded">
          {error}
        </div>
      )}

      {/* Overview Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center">
            <Globe className="h-8 w-8 text-blue-500" />
            <div className="ml-4">
              <p className="text-sm text-gray-500">Total Websites</p>
              <p className="text-2xl font-semibold text-gray-900">{websites.length} / {websiteLimit}</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center">
            <Users className="h-8 w-8 text-green-500" />
            <div className="ml-4">
              <p className="text-sm text-gray-500">Account Type</p>
              <p className="text-2xl font-semibold text-gray-900">
                {subscription?.status === 'active' ? 'Premium' : 'Free'}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center">
            <DollarSign className="h-8 w-8 text-purple-500" />
            <div className="ml-4">
              <p className="text-sm text-gray-500">Current Plan</p>
              <p className="text-2xl font-semibold text-gray-900">
                {subscription?.plan_id?.replace('price_', '').toUpperCase() || 'Free'}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center">
            <TrendingUp className="h-8 w-8 text-orange-500" />
            <div className="ml-4">
              <p className="text-sm text-gray-500">Status</p>
              <p className="text-2xl font-semibold text-gray-900">Active</p>
            </div>
          </div>
        </div>
      </div>

      {/* Analysis Modules */}
      <div className="mb-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">SEO Analysis Tools</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {analysisModules.map((module, index) => {
            const Icon = module.icon;
            const isPremiumFeature = index > 1 && !subscription?.status;
            
            return (
              <div
                key={index}
                className={`relative bg-white rounded-lg shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105 ${
                  isPremiumFeature ? 'opacity-75' : ''
                }`}
              >
                <div className="p-6">
                  <div className={`inline-flex items-center justify-center p-3 rounded-lg bg-${module.color}-100 text-${module.color}-600`}>
                    <Icon className="h-6 w-6" />
                  </div>
                  <h3 className="mt-4 text-lg font-semibold text-gray-900">{module.title}</h3>
                  <p className="mt-2 text-sm text-gray-500">{module.description}</p>
                  
                  {isPremiumFeature ? (
                    <Link to="/pricing">
                      <Button className="mt-4 w-full">
                        Upgrade to Access
                        <ArrowRight className="ml-2 h-4 w-4" />
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      className="mt-4 w-full"
                      onClick={() => navigate(`/dashboard${module.path}`)}
                    >
                      View Analysis
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </Button>
                  )}
                </div>
                
                {isPremiumFeature && (
                  <div className="absolute top-4 right-4">
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                      Premium
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* Add Website Section */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-600 rounded-lg shadow-lg p-8 mb-8">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-3xl font-bold text-white mb-4">
            Manage Your Websites
          </h1>
          <p className="text-blue-100 mb-8">
            Add and manage your websites from one central dashboard
          </p>
          {canAddWebsite ? (
            <form onSubmit={handleAddWebsite} className="flex gap-4">
              <Input
                type="url"
                value={newWebsiteUrl}
                onChange={(e) => setNewWebsiteUrl(e.target.value)}
                placeholder="example.com"
                formatUrl
                className="flex-1 px-4 py-2 rounded-lg border-2 border-white/20 bg-white/10 text-white placeholder-blue-200 focus:outline-none focus:border-white"
                required
              />
              <Button
                type="submit"
                disabled={loading}
                className="bg-white text-blue-600 hover:bg-blue-50"
              >
                {loading ? 'Adding...' : 'Add Website'}
              </Button>
            </form>
          ) : (
            <div className="text-center">
              <p className="text-white mb-4">
                You've reached your plan's website limit. Upgrade to add more websites.
              </p>
              <Link to="/pricing">
                <Button className="bg-white text-blue-600 hover:bg-blue-50">
                  Upgrade Plan
                  <ArrowRight className="ml-2 h-4 w-4" />
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* Websites Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {websites.map((website) => (
          <div
            key={website.id}
            className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow cursor-pointer"
            onClick={() => navigate(`/website/${website.id}`)}
          >
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <Globe className="h-6 w-6 text-blue-600" />
                  <h3 className="ml-2 text-lg font-semibold text-gray-900 truncate">
                    {new URL(website.url).hostname}
                  </h3>
                </div>
                <Button variant="ghost" onClick={(e) => {
                  e.stopPropagation();
                  window.open(website.url, '_blank');
                }}>
                  <Settings className="h-5 w-5" />
                </Button>
              </div>
              
              <div className="mt-4">
                <p className="text-sm text-gray-500">
                  Added: {new Date(website.created_at).toLocaleDateString()}
                </p>
                <p className="text-sm text-gray-500">
                  Status: <span className="text-green-600 font-medium">Active</span>
                </p>
              </div>

              <div className="mt-6">
                <span className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                  View Analysis →
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Subscription Section */}
      <div className="mt-8">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Subscription Management</h2>
          {subscription ? (
            <SubscriptionManager />
          ) : (
            <div className="text-center py-8">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Try our premium features
              </h3>
              <p className="text-gray-600 mb-6">
                Upgrade to a paid plan to unlock more features and analyze more websites.
              </p>
              <Link to="/pricing">
                <Button>
                  View Plans
                  <ArrowRight className="ml-2 h-4 w-4" />
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;