import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Zap, Clock, AlertTriangle } from 'lucide-react';

interface PageSpeedMetrics {
  loadTime: number;
  firstContentfulPaint: number;
  largestContentfulPaint: number;
  timeToInteractive: number;
  speedIndex: number;
}

interface PageSpeedTabProps {
  metrics: PageSpeedMetrics;
  history?: Array<{
    date: string;
    score: number;
  }>;
}

export const PageSpeedTab: React.FC<PageSpeedTabProps> = ({ metrics, history = [] }) => {
  const getScoreColor = (value: number) => {
    if (value >= 90) return 'text-green-500';
    if (value >= 50) return 'text-yellow-500';
    return 'text-red-500';
  };

  return (
    <div className="space-y-8">
      {/* Performance Score */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Performance Score</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-gray-500">Overall Score</span>
              <span className={`text-2xl font-bold ${getScoreColor(metrics.speedIndex)}`}>
                {metrics.speedIndex}
              </span>
            </div>
            {history.length > 0 && (
              <div className="h-48">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={history}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip />
                    <Line type="monotone" dataKey="score" stroke="#3B82F6" strokeWidth={2} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
          </div>
          <div className="space-y-4">
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Clock className="h-5 w-5 text-blue-500 mr-2" />
                  <span className="text-sm text-gray-700">Load Time</span>
                </div>
                <span className="text-sm font-semibold">{metrics.loadTime}s</span>
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Zap className="h-5 w-5 text-purple-500 mr-2" />
                  <span className="text-sm text-gray-700">First Paint</span>
                </div>
                <span className="text-sm font-semibold">{metrics.firstContentfulPaint}s</span>
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <AlertTriangle className="h-5 w-5 text-yellow-500 mr-2" />
                  <span className="text-sm text-gray-700">Time to Interactive</span>
                </div>
                <span className="text-sm font-semibold">{metrics.timeToInteractive}s</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Recommendations */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Performance Recommendations</h3>
        <div className="space-y-4">
          {metrics.loadTime > 3 && (
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <AlertTriangle className="h-5 w-5 text-yellow-500" />
              </div>
              <div className="ml-3">
                <h4 className="text-sm font-medium text-gray-900">Improve Load Time</h4>
                <p className="mt-1 text-sm text-gray-500">
                  Your page load time is above the recommended 3 seconds. Consider optimizing images,
                  minifying CSS/JS, and leveraging browser caching.
                </p>
              </div>
            </div>
          )}
          {metrics.firstContentfulPaint > 2 && (
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <AlertTriangle className="h-5 w-5 text-yellow-500" />
              </div>
              <div className="ml-3">
                <h4 className="text-sm font-medium text-gray-900">Optimize First Paint</h4>
                <p className="mt-1 text-sm text-gray-500">
                  First paint is taking longer than recommended. Consider reducing server response time
                  and optimizing critical rendering path.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};