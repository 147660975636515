import React from 'react';
import { Users, Target, Award, Zap } from 'lucide-react';

const About = () => {
  const stats = [
    { label: 'Websites Analyzed', value: '100K+' },
    { label: 'Happy Customers', value: '50K+' },
    { label: 'Countries Served', value: '120+' },
    { label: 'Team Members', value: '50+' }
  ];

  const team = [
    {
      name: 'Sarah Johnson',
      role: 'CEO & Founder',
      image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
      bio: '15+ years of SEO and digital marketing experience'
    },
    {
      name: 'Michael Chen',
      role: 'CTO',
      image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
      bio: 'Former Google engineer with expertise in search algorithms'
    },
    {
      name: 'Emma Davis',
      role: 'Head of SEO Strategy',
      image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
      bio: 'SEO strategist with 10+ years of experience'
    },
    {
      name: 'Alex Turner',
      role: 'Lead Developer',
      image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80',
      bio: 'Full-stack developer specializing in SEO tools'
    }
  ];

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative bg-blue-600">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover opacity-10"
            src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
            alt="Team collaboration"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold text-white sm:text-5xl lg:text-6xl">
              About GetSEOExpert
            </h1>
            <p className="mt-6 text-xl text-blue-100 max-w-3xl mx-auto">
              We're on a mission to make professional SEO tools accessible to businesses of all sizes.
              Our platform combines advanced technology with expert insights to help you succeed online.
            </p>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
            {stats.map((stat, index) => (
              <div key={index} className="text-center">
                <div className="text-4xl font-extrabold text-blue-600">{stat.value}</div>
                <div className="mt-2 text-lg font-medium text-gray-500">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Our Story Section */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Our Story</h2>
            <p className="mt-4 text-lg text-gray-500 max-w-3xl mx-auto">
              Founded in 2020, GetSEOExpert emerged from a simple observation: while SEO is crucial
              for online success, most businesses struggle to access professional SEO tools and expertise.
              We set out to change that by creating an all-in-one SEO platform that combines powerful
              technology with user-friendly design.
            </p>
          </div>

          <div className="mt-16">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
              {[
                {
                  icon: Target,
                  title: 'Our Mission',
                  description: 'To democratize SEO by making professional tools accessible to everyone'
                },
                {
                  icon: Award,
                  title: 'Our Values',
                  description: 'Innovation, transparency, and customer success drive everything we do'
                },
                {
                  icon: Users,
                  title: 'Our Team',
                  description: 'A diverse group of SEO experts, developers, and customer success specialists'
                },
                {
                  icon: Zap,
                  title: 'Our Technology',
                  description: 'Cutting-edge AI and machine learning powering our SEO analysis'
                }
              ].map((item, index) => {
                const Icon = item.icon;
                return (
                  <div key={index} className="text-center">
                    <div className="mx-auto h-12 w-12 text-blue-600">
                      <Icon className="h-12 w-12" />
                    </div>
                    <h3 className="mt-6 text-lg font-medium text-gray-900">{item.title}</h3>
                    <p className="mt-2 text-base text-gray-500">{item.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Team Section */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Meet Our Team</h2>
            <p className="mt-4 text-lg text-gray-500">
              The experts behind GetSEOExpert's success
            </p>
          </div>

          <div className="mt-16">
            <div className="grid grid-cols-1 gap-12 lg:grid-cols-4 sm:grid-cols-2">
              {team.map((member, index) => (
                <div key={index} className="text-center">
                  <div className="relative">
                    <img
                      className="mx-auto h-40 w-40 rounded-full object-cover"
                      src={member.image}
                      alt={member.name}
                    />
                    <div className="mt-4">
                      <h3 className="text-lg font-medium text-gray-900">{member.name}</h3>
                      <p className="text-blue-600">{member.role}</p>
                      <p className="mt-2 text-sm text-gray-500">{member.bio}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Values Section */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Our Values</h2>
            <p className="mt-4 text-lg text-gray-500">
              The principles that guide our work and relationships
            </p>
          </div>

          <div className="mt-16">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
              {[
                {
                  title: "Innovation First",
                  description: "We continuously push the boundaries of what's possible in SEO technology"
                },
                {
                  title: "Customer Success",
                  description: "Your success is our success. We're committed to helping you achieve your goals"
                },
                {
                  title: "Transparency",
                  description: "We believe in clear communication and honest relationships with our customers"
                }
              ].map((value, index) => (
                <div key={index} className="bg-white rounded-lg shadow-lg p-8">
                  <h3 className="text-xl font-medium text-gray-900">{value.title}</h3>
                  <p className="mt-4 text-gray-500">{value.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;