import React from 'react';
import { FileText, AlertTriangle, CheckCircle } from 'lucide-react';

interface ContentAnalysis {
  wordCount: number;
  readabilityScore: number;
  keywords: Array<{
    keyword: string;
    density: number;
    count: number;
  }>;
  suggestions: string[];
  issues: string[];
}

interface ContentAnalysisTabProps {
  analysis: ContentAnalysis;
}

export const ContentAnalysisTab: React.FC<ContentAnalysisTabProps> = ({ analysis }) => {
  const getReadabilityColor = (score: number) => {
    if (score >= 80) return 'text-green-500';
    if (score >= 60) return 'text-yellow-500';
    return 'text-red-500';
  };

  return (
    <div className="space-y-8">
      {/* Content Overview */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Content Overview</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-gray-50 rounded-lg p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FileText className="h-5 w-5 text-blue-500 mr-2" />
                <span className="text-sm text-gray-700">Word Count</span>
              </div>
              <span className="text-sm font-semibold">{analysis.wordCount}</span>
            </div>
          </div>
          <div className="bg-gray-50 rounded-lg p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FileText className="h-5 w-5 text-purple-500 mr-2" />
                <span className="text-sm text-gray-700">Readability</span>
              </div>
              <span className={`text-sm font-semibold ${getReadabilityColor(analysis.readabilityScore)}`}>
                {analysis.readabilityScore}
              </span>
            </div>
          </div>
          <div className="bg-gray-50 rounded-lg p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <FileText className="h-5 w-5 text-green-500 mr-2" />
                <span className="text-sm text-gray-700">Keywords</span>
              </div>
              <span className="text-sm font-semibold">{analysis.keywords.length}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Keyword Analysis */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Keyword Analysis</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Keyword
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Count
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Density
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {analysis.keywords.map((keyword, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {keyword.keyword}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {keyword.count}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {keyword.density.toFixed(2)}%
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {keyword.density >= 0.5 && keyword.density <= 2.5 ? (
                      <span className="flex items-center text-green-500">
                        <CheckCircle className="h-4 w-4 mr-1" />
                        Good
                      </span>
                    ) : (
                      <span className="flex items-center text-yellow-500">
                        <AlertTriangle className="h-4 w-4 mr-1" />
                        {keyword.density < 0.5 ? 'Low' : 'High'}
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Content Suggestions */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Content Suggestions</h3>
        <div className="space-y-4">
          {analysis.suggestions.map((suggestion, index) => (
            <div key={index} className="flex items-start">
              <div className="flex-shrink-0">
                <CheckCircle className="h-5 w-5 text-green-500" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-gray-700">{suggestion}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Content Issues */}
      {analysis.issues.length > 0 && (
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Content Issues</h3>
          <div className="space-y-4">
            {analysis.issues.map((issue, index) => (
              <div key={index} className="flex items-start">
                <div className="flex-shrink-0">
                  <AlertTriangle className="h-5 w-5 text-yellow-500" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-gray-700">{issue}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};