import React from 'react';
import { Shield, Search, BarChart2, Globe, Zap, Star, Code, Lock, Smartphone } from 'lucide-react';

const Features = () => {
  const features = [
    {
      icon: Search,
      title: "Advanced SEO Analysis",
      description: "Get comprehensive insights into your website's SEO performance with our advanced analysis tools. We check everything from meta tags to content optimization.",
      benefits: [
        "In-depth keyword analysis",
        "Content optimization suggestions",
        "Technical SEO audits"
      ]
    },
    {
      icon: BarChart2,
      title: "Real-time Performance Tracking",
      description: "Monitor your website's SEO performance in real-time. Track rankings, traffic, and key metrics to stay ahead of the competition.",
      benefits: [
        "Live performance metrics",
        "Competitor tracking",
        "Custom reporting"
      ]
    },
    {
      icon: Globe,
      title: "International SEO Support",
      description: "Optimize your website for global audiences with our international SEO tools and recommendations.",
      benefits: [
        "Multi-language support",
        "Regional ranking analysis",
        "International targeting"
      ]
    },
    {
      icon: Code,
      title: "Technical SEO Tools",
      description: "Identify and fix technical SEO issues with our comprehensive suite of diagnostic tools.",
      benefits: [
        "Site structure analysis",
        "Mobile optimization",
        "Speed optimization"
      ]
    },
    {
      icon: Lock,
      title: "Security & Compliance",
      description: "Ensure your SEO practices are compliant with search engine guidelines and web standards.",
      benefits: [
        "Safe optimization techniques",
        "Guidelines compliance",
        "Regular updates"
      ]
    },
    {
      icon: Smartphone,
      title: "Mobile SEO Optimization",
      description: "Optimize your website for mobile users with our specialized mobile SEO tools and recommendations.",
      benefits: [
        "Mobile-first indexing",
        "Responsive design checks",
        "Mobile speed optimization"
      ]
    }
  ];

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-600 to-indigo-700">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
            alt="SEO Features Background"
            className="w-full h-full object-cover opacity-10"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
            Powerful SEO Features
          </h1>
          <p className="mt-6 text-xl text-blue-100 max-w-3xl">
            Discover our comprehensive suite of SEO tools designed to boost your website's visibility
            and drive organic traffic. From technical analysis to content optimization, we've got you covered.
          </p>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-3">
          {features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <div key={index} className="relative">
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                  <Icon className="h-6 w-6" />
                </div>
                <div className="ml-16">
                  <h2 className="text-lg font-medium text-gray-900">{feature.title}</h2>
                  <p className="mt-2 text-base text-gray-500">{feature.description}</p>
                  <ul className="mt-4 space-y-2">
                    {feature.benefits.map((benefit, benefitIndex) => (
                      <li key={benefitIndex} className="flex items-center text-gray-600">
                        <Shield className="h-4 w-4 mr-2 text-blue-500" />
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Comparison Section */}
      <div className="bg-gray-50 py-16 sm:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Why Choose Our SEO Tools?
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Compare our features with traditional SEO solutions
            </p>
          </div>

          <div className="mt-16">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Feature
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      GetSEOExpert
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Traditional Tools
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {[
                    { name: "Real-time Analysis", us: true, them: false },
                    { name: "AI-Powered Insights", us: true, them: false },
                    { name: "Mobile Optimization", us: true, them: true },
                    { name: "Technical SEO Audit", us: true, them: true },
                    { name: "Content Optimization", us: true, them: false },
                    { name: "24/7 Support", us: true, them: false },
                  ].map((feature, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {feature.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                        {feature.us ? (
                          <CheckIcon className="h-5 w-5 text-green-500 mx-auto" />
                        ) : (
                          <XIcon className="h-5 w-5 text-red-500 mx-auto" />
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                        {feature.them ? (
                          <CheckIcon className="h-5 w-5 text-green-500 mx-auto" />
                        ) : (
                          <XIcon className="h-5 w-5 text-red-500 mx-auto" />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CheckIcon = ({ className }: { className?: string }) => (
  <svg className={className} fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
  </svg>
);

const XIcon = ({ className }: { className?: string }) => (
  <svg className={className} fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
  </svg>
);

export default Features;