import { supabase } from './supabase';

export async function testConnection() {
  try {
    // First check if we can connect to Supabase
    const { data: { session } } = await supabase.auth.getSession();
    
    // Call the test-connection function with auth header
    const { data, error } = await supabase.functions.invoke('test-connection', {
      headers: session ? {
        Authorization: `Bearer ${session.access_token}`
      } : undefined
    });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Connection test failed:', error);
    throw new Error('Failed to connect to service. Please try again.');
  }
}

export async function checkDatabaseConnection() {
  try {
    // Simple query to check database connection
    const { data, error } = await supabase
      .from('websites')
      .select('count')
      .limit(1)
      .single();
    
    if (error) throw error;
    return { connected: true, count: data?.count };
  } catch (error) {
    console.error('Database connection failed:', error);
    throw new Error('Failed to connect to database. Please try again.');
  }
}