import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Globe, TrendingUp, Link as LinkIcon, FileText } from 'lucide-react';

interface Competitor {
  domain: string;
  metrics: {
    authority: number;
    traffic: number;
    keywords: number;
    backlinks: number;
  };
  commonKeywords: Array<{
    keyword: string;
    ourPosition: number;
    theirPosition: number;
    volume: number;
  }>;
  backlinks: Array<{
    domain: string;
    authority: number;
  }>;
}

interface CompetitorAnalysisTabProps {
  competitors: Competitor[];
}

export const CompetitorAnalysisTab: React.FC<CompetitorAnalysisTabProps> = ({ competitors }) => {
  return (
    <div className="space-y-8">
      {/* Competitors Overview */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Competitors Overview</h3>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={competitors}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="domain" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="metrics.authority" name="Domain Authority" fill="#3B82F6" />
              <Bar dataKey="metrics.traffic" name="Traffic" fill="#10B981" />
              <Bar dataKey="metrics.keywords" name="Keywords" fill="#8B5CF6" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Detailed Competitor Analysis */}
      {competitors.map((competitor, index) => (
        <div key={index} className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <Globe className="h-6 w-6 text-blue-500 mr-2" />
              <h3 className="text-lg font-semibold text-gray-900">{competitor.domain}</h3>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <TrendingUp className="h-5 w-5 text-blue-500 mr-2" />
                  <span className="text-sm text-gray-700">Authority</span>
                </div>
                <span className="text-lg font-semibold">{competitor.metrics.authority}</span>
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Globe className="h-5 w-5 text-green-500 mr-2" />
                  <span className="text-sm text-gray-700">Traffic</span>
                </div>
                <span className="text-lg font-semibold">{competitor.metrics.traffic.toLocaleString()}</span>
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <FileText className="h-5 w-5 text-purple-500 mr-2" />
                  <span className="text-sm text-gray-700">Keywords</span>
                </div>
                <span className="text-lg font-semibold">{competitor.metrics.keywords.toLocaleString()}</span>
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <LinkIcon className="h-5 w-5 text-orange-500 mr-2" />
                  <span className="text-sm text-gray-700">Backlinks</span>
                </div>
                <span className="text-lg font-semibold">{competitor.metrics.backlinks.toLocaleString()}</span>
              </div>
            </div>
          </div>

          {/* Common Keywords */}
          <div className="mb-6">
            <h4 className="text-md font-semibold text-gray-900 mb-4">Common Keywords</h4>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Keyword
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Our Position
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Their Position
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Volume
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {competitor.commonKeywords.map((keyword, idx) => (
                    <tr key={idx}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {keyword.keyword}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {keyword.ourPosition}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {keyword.theirPosition}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {keyword.volume.toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};