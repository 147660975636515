import { loadStripe } from '@stripe/stripe-js';
import { supabase } from './supabase';

// Initialize Stripe with the publishable key
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

interface CheckoutResult {
  error?: string;
  sessionId?: string;
  url?: string;
}

export const createCheckoutSession = async (priceId: string): Promise<CheckoutResult> => {
  try {
    // Get current session
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      return { error: 'No active session found' };
    }

    // Call the Supabase Edge Function
    const { data, error } = await supabase.functions.invoke('create-checkout-session', {
      body: { priceId },
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      }
    });

    if (error) {
      console.error('Supabase function error:', error);
      return { error: error.message || 'Failed to create checkout session' };
    }

    if (!data) {
      console.error('No data returned from function');
      return { error: 'No response from server' };
    }

    // Handle error response from the function
    if (data.error) {
      console.error('Function error:', data.error);
      return { error: data.error };
    }

    // Check for direct URL in response
    if (data.url) {
      window.location.href = data.url;
      return { sessionId: data.sessionId, url: data.url };
    }

    // If no URL but we have a session ID, use Stripe.js redirect
    if (data.sessionId) {
      const stripe = await stripePromise;
      if (!stripe) {
        return { error: 'Failed to load Stripe' };
      }

      const { error: stripeError } = await stripe.redirectToCheckout({
        sessionId: data.sessionId
      });

      if (stripeError) {
        console.error('Stripe redirect error:', stripeError);
        return { error: stripeError.message };
      }

      return { sessionId: data.sessionId };
    }

    return { error: 'Invalid response from server' };
  } catch (error) {
    console.error('Checkout error:', error);
    return { 
      error: error instanceof Error ? error.message : 'An unexpected error occurred'
    };
  }
};

export const createPortalSession = async () => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      return { error: 'No active session found' };
    }

    // Get customer ID
    const { data: subscription } = await supabase
      .from('subscriptions')
      .select('stripe_customer_id')
      .eq('user_id', session.user.id)
      .single();

    if (!subscription?.stripe_customer_id) {
      return { error: 'No subscription found' };
    }

    const { data, error } = await supabase.functions.invoke('create-portal-session', {
      body: {},
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      }
    });

    if (error) {
      return { error: error.message };
    }

    return { url: data?.url };
  } catch (error) {
    console.error('Error:', error);
    return { error: error instanceof Error ? error.message : 'An error occurred' };
  }
};

export const getSubscriptionDetails = async () => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      return null;
    }

    const { data: subscription } = await supabase
      .from('subscriptions')
      .select('*')
      .eq('user_id', session.user.id)
      .single();

    return subscription;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};