import * as React from 'react';
import { cn, formatUrl } from '@/lib/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  formatUrl?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, formatUrl: shouldFormatUrl, onChange, ...props }, ref) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (shouldFormatUrl && type === 'url') {
        // Remove protocols while typing for better UX
        const value = e.target.value.replace(/^https?:\/\//, '');
        e.target.value = value;
      }
      onChange?.(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (shouldFormatUrl && type === 'url' && e.target.value) {
        // Format URL with HTTPS on blur
        const formattedUrl = formatUrl(e.target.value);
        if (formattedUrl) {
          e.target.value = formattedUrl;
        }
      }
      props.onBlur?.(e);
    };

    return (
      <input
        type={type}
        className={cn(
          "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      />
    );
  }
);

Input.displayName = "Input";

export { Input };