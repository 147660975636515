import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  FileText, Plus, Edit2, Trash2, Eye, ArrowLeft, 
  Calendar, User, Tag, Globe, AlertTriangle 
} from 'lucide-react';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { supabase } from '../lib/supabase';

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  status: 'draft' | 'published' | 'archived';
  category_id: string;
  published_at: string | null;
  created_at: string;
  updated_at: string;
}

interface Category {
  id: string;
  name: string;
  slug: string;
}

const BlogManager = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showNewPostModal, setShowNewPostModal] = useState(false);
  const [newPost, setNewPost] = useState({
    topic: '',
    keywords: '',
    tone: 'professional',
    targetAudience: '',
    wordCount: 1000
  });

  useEffect(() => {
    loadBlogPosts();
    loadCategories();
  }, []);

  const loadBlogPosts = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      const { data: posts, error: postsError } = await supabase
        .from('blog_posts')
        .select(`
          id,
          title,
          excerpt,
          status,
          category_id,
          published_at,
          created_at,
          updated_at
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (postsError) throw postsError;
      setPosts(posts || []);
    } catch (err) {
      console.error('Error loading blog posts:', err);
      setError(err instanceof Error ? err.message : 'Failed to load blog posts');
    } finally {
      setLoading(false);
    }
  };

  const loadCategories = async () => {
    try {
      const { data: categories, error: categoriesError } = await supabase
        .from('blog_categories')
        .select('id, name, slug');

      if (categoriesError) throw categoriesError;
      setCategories(categories || []);
    } catch (err) {
      console.error('Error loading categories:', err);
    }
  };

  const handleGeneratePost = async () => {
    try {
      setLoading(true);
      
      // Call the generate-blog-post function
      const { data, error } = await supabase.functions.invoke('generate-blog-post', {
        body: {
          topic: newPost.topic,
          keywords: newPost.keywords.split(',').map(k => k.trim()),
          tone: newPost.tone,
          targetAudience: newPost.targetAudience,
          wordCount: newPost.wordCount
        }
      });

      if (error) throw error;

      // Create new blog post
      const { data: post, error: postError } = await supabase
        .from('blog_posts')
        .insert({
          title: data.title,
          excerpt: data.metaDescription,
          content: data.content,
          seo_title: data.title,
          seo_description: data.metaDescription,
          status: 'draft',
          ai_generated: true,
          ai_suggestions: data.seoSuggestions
        })
        .select()
        .single();

      if (postError) throw postError;

      // Add tags
      if (data.suggestedTags?.length > 0) {
        // First ensure tags exist
        const { data: tags, error: tagsError } = await supabase
          .from('blog_tags')
          .upsert(
            data.suggestedTags.map((tag: string) => ({
              name: tag,
              slug: tag.toLowerCase().replace(/\s+/g, '-')
            }))
          )
          .select();

        if (tagsError) throw tagsError;

        // Then link tags to post
        if (tags) {
          const { error: linkError } = await supabase
            .from('blog_posts_tags')
            .insert(
              tags.map(tag => ({
                post_id: post.id,
                tag_id: tag.id
              }))
            );

          if (linkError) throw linkError;
        }
      }

      setShowNewPostModal(false);
      loadBlogPosts();
    } catch (err) {
      console.error('Error generating blog post:', err);
      setError(err instanceof Error ? err.message : 'Failed to generate blog post');
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'published':
        return 'bg-green-100 text-green-800';
      case 'draft':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <div>
          <Button
            variant="ghost"
            onClick={() => navigate('/dashboard')}
            className="mb-4"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Dashboard
          </Button>
          <h1 className="text-2xl font-bold text-gray-900">Blog Manager</h1>
          <p className="text-gray-500 mt-2">
            Create and manage your blog posts with AI assistance
          </p>
        </div>
        <Button onClick={() => setShowNewPostModal(true)}>
          <Plus className="h-4 w-4 mr-2" />
          New Post
        </Button>
      </div>

      {error && (
        <div className="mb-8 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
          <AlertTriangle className="h-5 w-5 mr-2" />
          {error}
        </div>
      )}

      {/* Blog Posts List */}
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900">Your Blog Posts</h2>
        </div>

        <div className="divide-y divide-gray-200">
          {posts.map((post) => (
            <div key={post.id} className="p-6 hover:bg-gray-50">
              <div className="flex items-center justify-between">
                <div className="flex-1">
                  <h3 className="text-lg font-medium text-gray-900">{post.title}</h3>
                  <p className="mt-1 text-sm text-gray-500">{post.excerpt}</p>
                  <div className="mt-2 flex items-center space-x-4">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(post.status)}`}>
                      {post.status.charAt(0).toUpperCase() + post.status.slice(1)}
                    </span>
                    <span className="text-sm text-gray-500">
                      <Calendar className="inline-block h-4 w-4 mr-1" />
                      {new Date(post.created_at).toLocaleDateString()}
                    </span>
                    <span className="text-sm text-gray-500">
                      <Tag className="inline-block h-4 w-4 mr-1" />
                      {categories.find(c => c.id === post.category_id)?.name || 'Uncategorized'}
                    </span>
                  </div>
                </div>
                <div className="ml-4 flex items-center space-x-2">
                  <Button variant="ghost" onClick={() => navigate(`/blog/${post.id}`)}>
                    <Eye className="h-4 w-4" />
                  </Button>
                  <Button variant="ghost" onClick={() => navigate(`/blog/${post.id}/edit`)}>
                    <Edit2 className="h-4 w-4" />
                  </Button>
                  <Button variant="ghost" className="text-red-600 hover:text-red-800">
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>
          ))}

          {posts.length === 0 && (
            <div className="p-6 text-center text-gray-500">
              No blog posts yet. Click "New Post" to create one.
            </div>
          )}
        </div>
      </div>

      {/* New Post Modal */}
      {showNewPostModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 max-w-2xl w-full">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Create New Blog Post</h2>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Topic</label>
                <Input
                  type="text"
                  value={newPost.topic}
                  onChange={(e) => setNewPost({ ...newPost, topic: e.target.value })}
                  placeholder="E.g., The Impact of Core Web Vitals on SEO"
                  className="mt-1"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Keywords</label>
                <Input
                  type="text"
                  value={newPost.keywords}
                  onChange={(e) => setNewPost({ ...newPost, keywords: e.target.value })}
                  placeholder="core web vitals, page speed, user experience (comma separated)"
                  className="mt-1"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Tone</label>
                <select
                  value={newPost.tone}
                  onChange={(e) => setNewPost({ ...newPost, tone: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="professional">Professional</option>
                  <option value="conversational">Conversational</option>
                  <option value="technical">Technical</option>
                  <option value="educational">Educational</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Target Audience</label>
                <Input
                  type="text"
                  value={newPost.targetAudience}
                  onChange={(e) => setNewPost({ ...newPost, targetAudience: e.target.value })}
                  placeholder="E.g., SEO professionals and website owners"
                  className="mt-1"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Word Count</label>
                <Input
                  type="number"
                  value={newPost.wordCount}
                  onChange={(e) => setNewPost({ ...newPost, wordCount: parseInt(e.target.value) })}
                  min="500"
                  max="3000"
                  className="mt-1"
                />
              </div>
            </div>

            <div className="mt-8 flex justify-end space-x-4">
              <Button
                variant="ghost"
                onClick={() => setShowNewPostModal(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={handleGeneratePost}
                disabled={loading || !newPost.topic || !newPost.keywords || !newPost.targetAudience}
              >
                {loading ? 'Generating...' : 'Generate Post'}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogManager;