import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Key, RefreshCw, AlertTriangle, Check, X } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { Button } from '../components/ui/button';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

interface ApiKey {
  id: string;
  service_name: string;
  api_key: string;
  is_active: boolean;
  daily_limit: number;
  monthly_limit: number;
  daily_usage: number;
  monthly_usage: number;
  last_used_at: string;
  configuration: Record<string, any>;
}

const ApiKeys = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [usageStats, setUsageStats] = useState<any[]>([]);
  const [selectedKey, setSelectedKey] = useState<ApiKey | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    checkAccess();
    loadApiKeys();
  }, []);

  const checkAccess = async () => {
    try {
      const { data } = await supabase
        .from('admin_users')
        .select('id')
        .single();

      if (!data) {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error checking access:', error);
      navigate('/dashboard');
    }
  };

  const loadApiKeys = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase.rpc('get_api_keys_stats');
      
      if (error) throw error;
      
      setApiKeys(data.api_keys || []);
      setUsageStats(data.usage_stats || []);
    } catch (error) {
      console.error('Error loading API keys:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateKey = async (key: ApiKey) => {
    try {
      const { error } = await supabase
        .from('api_keys')
        .update({
          api_key: key.api_key,
          is_active: key.is_active,
          daily_limit: key.daily_limit,
          monthly_limit: key.monthly_limit,
          configuration: key.configuration,
          updated_at: new Date().toISOString()
        })
        .eq('id', key.id);

      if (error) throw error;
      
      loadApiKeys();
      setShowEditModal(false);
      setSelectedKey(null);
    } catch (error) {
      console.error('Error updating API key:', error);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-900">API Keys Management</h1>
        <Button onClick={() => navigate('/super-admin')}>
          Back to Super Admin
        </Button>
      </div>

      {/* Usage Chart */}
      <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">API Usage Trends</h2>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={usageStats}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="date" 
                tickFormatter={(date) => new Date(date).toLocaleDateString()}
              />
              <YAxis />
              <Tooltip 
                labelFormatter={(date) => new Date(date).toLocaleDateString()}
              />
              {Array.from(new Set(usageStats.map(stat => stat.service_name))).map((service, index) => (
                <Line
                  key={service}
                  type="monotone"
                  dataKey="total_calls"
                  name={service}
                  stroke={`hsl(${index * 45}, 70%, 50%)`}
                  strokeWidth={2}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* API Keys List */}
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900">API Keys</h2>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Service
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Daily Usage
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Monthly Usage
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Last Used
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {apiKeys.map((key) => (
                <tr 
                  key={key.id}
                  className="hover:bg-gray-50 cursor-pointer"
                  onClick={() => {
                    setSelectedKey(key);
                    setShowEditModal(true);
                  }}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <Key className="h-5 w-5 text-gray-400" />
                      <span className="ml-2 text-sm font-medium text-gray-900">
                        {key.service_name}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                      ${key.is_active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                      {key.is_active ? 'Active' : 'Inactive'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {key.daily_usage} / {key.daily_limit || '∞'}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {key.monthly_usage} / {key.monthly_limit || '∞'}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">
                      {key.last_used_at ? new Date(key.last_used_at).toLocaleDateString() : 'Never'}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <Button
                      variant="ghost"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUpdateKey({
                          ...key,
                          is_active: !key.is_active
                        });
                      }}
                      className={key.is_active ? 'text-red-600' : 'text-green-600'}
                    >
                      {key.is_active ? 'Disable' : 'Enable'}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Edit Modal */}
      {showEditModal && selectedKey && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 max-w-2xl w-full">
            <div className="flex justify-between items-start">
              <h2 className="text-2xl font-bold text-gray-900">Edit API Key</h2>
              <button
                onClick={() => {
                  setShowEditModal(false);
                  setSelectedKey(null);
                }}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            
            <div className="mt-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Service Name</label>
                <div className="mt-1 text-sm text-gray-900">{selectedKey.service_name}</div>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">API Key</label>
                <input
                  type="text"
                  value={selectedKey.api_key}
                  onChange={(e) => setSelectedKey({ ...selectedKey, api_key: e.target.value })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Daily Limit</label>
                <input
                  type="number"
                  value={selectedKey.daily_limit || ''}
                  onChange={(e) => setSelectedKey({ ...selectedKey, daily_limit: parseInt(e.target.value) })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Monthly Limit</label>
                <input
                  type="number"
                  value={selectedKey.monthly_limit || ''}
                  onChange={(e) => setSelectedKey({ ...selectedKey, monthly_limit: parseInt(e.target.value) })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Configuration</label>
                <textarea
                  value={JSON.stringify(selectedKey.configuration, null, 2)}
                  onChange={(e) => {
                    try {
                      const config = JSON.parse(e.target.value);
                      setSelectedKey({ ...selectedKey, configuration: config });
                    } catch (error) {
                      // Invalid JSON, ignore
                    }
                  }}
                  rows={4}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm font-mono"
                />
              </div>

              <div className="flex justify-end space-x-3 mt-6">
                <Button
                  variant="ghost"
                  onClick={() => {
                    setShowEditModal(false);
                    setSelectedKey(null);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => handleUpdateKey(selectedKey)}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApiKeys;