import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import Pricing from './pages/Pricing';
import Admin from './pages/Admin';
import SuperAdmin from './pages/SuperAdmin';
import SuperAdminLogin from './pages/SuperAdminLogin';
import ApiKeys from './pages/ApiKeys';
import Features from './pages/Features';
import Blog from './pages/Blog';
import BlogPost from './components/BlogPost';
import BlogManager from './pages/BlogManager';
import About from './pages/About';
import Contact from './pages/Contact';
import Careers from './pages/Careers';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import RefundPolicy from './pages/RefundPolicy';
import WebsiteAnalysis from './pages/WebsiteAnalysis';
import PageSpeedInsights from './pages/PageSpeedInsights';
import SearchConsoleAnalytics from './pages/SearchConsoleAnalytics';
import OnPageSEO from './pages/OnPageSEO';
import OffPageSEO from './pages/OffPageSEO';
import KeywordAnalysis from './pages/KeywordAnalysis';
import ContentAnalysis from './pages/ContentAnalysis';
import CompetitorAnalysis from './pages/CompetitorAnalysis';
import AuthGuard from './components/AuthGuard';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <div className="min-h-screen bg-gray-50 flex flex-col">
          <Navbar />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={
                <AuthGuard>
                  <Dashboard />
                </AuthGuard>
              } />
              <Route path="/dashboard/blog" element={
                <AuthGuard>
                  <BlogManager />
                </AuthGuard>
              } />
              <Route path="/dashboard/page-speed" element={
                <AuthGuard>
                  <PageSpeedInsights />
                </AuthGuard>
              } />
              <Route path="/dashboard/search-console" element={
                <AuthGuard>
                  <SearchConsoleAnalytics />
                </AuthGuard>
              } />
              <Route path="/dashboard/on-page-seo" element={
                <AuthGuard>
                  <OnPageSEO />
                </AuthGuard>
              } />
              <Route path="/dashboard/off-page-seo" element={
                <AuthGuard>
                  <OffPageSEO />
                </AuthGuard>
              } />
              <Route path="/dashboard/keywords" element={
                <AuthGuard>
                  <KeywordAnalysis />
                </AuthGuard>
              } />
              <Route path="/dashboard/content" element={
                <AuthGuard>
                  <ContentAnalysis />
                </AuthGuard>
              } />
              <Route path="/dashboard/competitors" element={
                <AuthGuard>
                  <CompetitorAnalysis />
                </AuthGuard>
              } />
              <Route path="/website/:id" element={
                <AuthGuard>
                  <WebsiteAnalysis />
                </AuthGuard>
              } />
              <Route path="/admin" element={
                <AuthGuard>
                  <Admin />
                </AuthGuard>
              } />
              <Route path="/super-admin" element={
                <AuthGuard>
                  <SuperAdmin />
                </AuthGuard>
              } />
              <Route path="/super-admin-login" element={<SuperAdminLogin />} />
              <Route path="/super-admin/api-keys" element={
                <AuthGuard>
                  <ApiKeys />
                </AuthGuard>
              } />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/features" element={<Features />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:id" element={<BlogPost />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/refund-policy" element={<RefundPolicy />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App;