import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Search, FileText, Link as LinkIcon, AlertTriangle, CheckCircle } from 'lucide-react';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { formatUrl } from '../lib/utils';

interface OnPageAnalysis {
  title: {
    text: string;
    length: number;
    score: number;
    issues: string[];
  };
  metaDescription: {
    text: string;
    length: number;
    score: number;
    issues: string[];
  };
  headings: {
    h1Count: number;
    h2Count: number;
    h3Count: number;
    score: number;
    issues: string[];
  };
  content: {
    wordCount: number;
    readabilityScore: number;
    score: number;
    issues: string[];
  };
  images: {
    total: number;
    withAlt: number;
    withoutAlt: number;
    score: number;
    issues: string[];
  };
  internalLinks: {
    count: number;
    broken: number;
    score: number;
    issues: string[];
  };
}

const OnPageSEO = () => {
  const navigate = useNavigate();
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [analysis, setAnalysis] = useState<OnPageAnalysis | null>(null);

  const analyzeUrl = async () => {
    try {
      setLoading(true);
      setError(null);

      // Format and validate URL
      const formattedUrl = formatUrl(url);
      if (!formattedUrl) {
        throw new Error('Please enter a valid URL');
      }

      // Fetch webpage through our proxy function
      const response = await fetch('/api/fetch-webpage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url: formattedUrl })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Failed to fetch webpage: ${response.statusText}`);
      }

      const { html } = await response.json();
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');

      // Rest of the analysis code remains the same
      const titleElement = doc.querySelector('title');
      const metaDescription = doc.querySelector('meta[name="description"]');
      const h1Elements = doc.querySelectorAll('h1');
      const h2Elements = doc.querySelectorAll('h2');
      const h3Elements = doc.querySelectorAll('h3');
      const images = doc.querySelectorAll('img');
      const links = doc.querySelectorAll('a');
      const paragraphs = doc.querySelectorAll('p');

      // Calculate scores and issues
      const titleAnalysis = analyzeTitleTag(titleElement?.textContent || '');
      const descriptionAnalysis = analyzeMetaDescription(metaDescription?.getAttribute('content') || '');
      const headingsAnalysis = analyzeHeadings(h1Elements.length, h2Elements.length, h3Elements.length);
      const contentAnalysis = analyzeContent(paragraphs);
      const imageAnalysis = analyzeImages(images);
      const linkAnalysis = analyzeLinks(links, formattedUrl);

      setAnalysis({
        title: titleAnalysis,
        metaDescription: descriptionAnalysis,
        headings: headingsAnalysis,
        content: contentAnalysis,
        images: imageAnalysis,
        internalLinks: linkAnalysis
      });
    } catch (err) {
      console.error('Error analyzing URL:', err);
      setError(err instanceof Error ? err.message : 'Failed to analyze URL');
    } finally {
      setLoading(false);
    }
  };

  const analyzeTitleTag = (title: string) => {
    const issues: string[] = [];
    let score = 100;

    if (!title) {
      issues.push('Missing title tag');
      score = 0;
    } else {
      if (title.length < 30) {
        issues.push('Title is too short (recommended: 30-60 characters)');
        score -= 30;
      }
      if (title.length > 60) {
        issues.push('Title is too long (recommended: 30-60 characters)');
        score -= 30;
      }
    }

    return {
      text: title,
      length: title.length,
      score: Math.max(0, score),
      issues
    };
  };

  const analyzeMetaDescription = (description: string) => {
    const issues: string[] = [];
    let score = 100;

    if (!description) {
      issues.push('Missing meta description');
      score = 0;
    } else {
      if (description.length < 120) {
        issues.push('Meta description is too short (recommended: 120-160 characters)');
        score -= 30;
      }
      if (description.length > 160) {
        issues.push('Meta description is too long (recommended: 120-160 characters)');
        score -= 30;
      }
    }

    return {
      text: description,
      length: description.length,
      score: Math.max(0, score),
      issues
    };
  };

  const analyzeHeadings = (h1Count: number, h2Count: number, h3Count: number) => {
    const issues: string[] = [];
    let score = 100;

    if (h1Count === 0) {
      issues.push('Missing H1 heading');
      score -= 40;
    }
    if (h1Count > 1) {
      issues.push('Multiple H1 headings found (recommended: only one H1)');
      score -= 20;
    }
    if (h2Count === 0) {
      issues.push('No H2 headings found');
      score -= 20;
    }

    return {
      h1Count,
      h2Count,
      h3Count,
      score: Math.max(0, score),
      issues
    };
  };

  const analyzeContent = (paragraphs: NodeListOf<HTMLParagraphElement>) => {
    const issues: string[] = [];
    let score = 100;
    let wordCount = 0;

    // Calculate total word count
    paragraphs.forEach(p => {
      wordCount += p.textContent?.split(/\s+/).filter(Boolean).length || 0;
    });

    if (wordCount < 300) {
      issues.push('Content is too short (recommended: at least 300 words)');
      score -= 30;
    }

    // Simple readability score (0-100)
    const readabilityScore = Math.min(100, Math.max(0, wordCount / 10));

    return {
      wordCount,
      readabilityScore,
      score: Math.max(0, score),
      issues
    };
  };

  const analyzeImages = (images: NodeListOf<HTMLImageElement>) => {
    const issues: string[] = [];
    let score = 100;
    const total = images.length;
    const withAlt = Array.from(images).filter(img => img.hasAttribute('alt')).length;
    const withoutAlt = total - withAlt;

    if (withoutAlt > 0) {
      issues.push(`${withoutAlt} images missing alt text`);
      score -= (withoutAlt / total) * 100;
    }

    return {
      total,
      withAlt,
      withoutAlt,
      score: Math.max(0, score),
      issues
    };
  };

  const analyzeLinks = (links: NodeListOf<HTMLAnchorElement>, baseUrl: string) => {
    const issues: string[] = [];
    let score = 100;
    const internalLinks = Array.from(links).filter(link => {
      try {
        const href = new URL(link.href, baseUrl);
        return href.hostname === new URL(baseUrl).hostname;
      } catch {
        return false;
      }
    });

    if (internalLinks.length === 0) {
      issues.push('No internal links found');
      score -= 50;
    }

    return {
      count: internalLinks.length,
      broken: 0, // Would require additional checks
      score: Math.max(0, score),
      issues
    };
  };

  const getScoreColor = (score: number) => {
    if (score >= 90) return 'text-green-500';
    if (score >= 70) return 'text-yellow-500';
    return 'text-red-500';
  };

  const getScoreBackground = (score: number) => {
    if (score >= 90) return 'bg-green-50';
    if (score >= 70) return 'bg-yellow-50';
    return 'bg-red-50';
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <div>
          <Button
            variant="ghost"
            onClick={() => navigate('/dashboard')}
            className="mb-4"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Dashboard
          </Button>
          <h1 className="text-2xl font-bold text-gray-900">On-Page SEO Analysis</h1>
          <p className="text-gray-500 mt-2">
            Analyze your website's on-page SEO elements and get recommendations for improvement
          </p>
        </div>
      </div>

      {/* URL Input */}
      <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
        <div className="max-w-3xl mx-auto">
          <div className="flex gap-4">
            <Input
              type="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter website URL (e.g., example.com)"
              formatUrl
              className="flex-1"
            />
            <Button
              onClick={analyzeUrl}
              disabled={loading || !url}
              className="flex items-center"
            >
              {loading ? (
                <>
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                  Analyzing...
                </>
              ) : (
                <>
                  <Search className="h-4 w-4 mr-2" />
                  Analyze
                </>
              )}
            </Button>
          </div>

          {error && (
            <div className="mt-4 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
              <AlertTriangle className="h-5 w-5 mr-2" />
              {error}
            </div>
          )}
        </div>
      </div>

      {/* Analysis Results */}
      {analysis && (
        <div className="space-y-8">
          {/* Meta Tags Analysis */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-6">
              <FileText className="h-6 w-6 text-blue-500 mr-2" />
              <h2 className="text-lg font-semibold text-gray-900">Meta Tags Analysis</h2>
            </div>

            <div className="space-y-6">
              {/* Title Analysis */}
              <div className={`${getScoreBackground(analysis.title.score)} rounded-lg p-4`}>
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-sm font-medium text-gray-900">Title Tag</h3>
                  <span className={`text-sm font-semibold ${getScoreColor(analysis.title.score)}`}>
                    {analysis.title.score}%
                  </span>
                </div>
                <p className="text-sm text-gray-600 mb-2">
                  Length: {analysis.title.length} characters
                </p>
                <p className="text-sm text-gray-600 mb-2">
                  Content: {analysis.title.text || 'No title found'}
                </p>
                {analysis.title.issues.map((issue, index) => (
                  <div key={index} className="flex items-center text-sm text-red-600">
                    <AlertTriangle className="h-4 w-4 mr-1" />
                    {issue}
                  </div>
                ))}
              </div>

              {/* Meta Description Analysis */}
              <div className={`${getScoreBackground(analysis.metaDescription.score)} rounded-lg p-4`}>
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-sm font-medium text-gray-900">Meta Description</h3>
                  <span className={`text-sm font-semibold ${getScoreColor(analysis.metaDescription.score)}`}>
                    {analysis.metaDescription.score}%
                  </span>
                </div>
                <p className="text-sm text-gray-600 mb-2">
                  Length: {analysis.metaDescription.length} characters
                </p>
                <p className="text-sm text-gray-600 mb-2">
                  Content: {analysis.metaDescription.text || 'No meta description found'}
                </p>
                {analysis.metaDescription.issues.map((issue, index) => (
                  <div key={index} className="flex items-center text-sm text-red-600">
                    <AlertTriangle className="h-4 w-4 mr-1" />
                    {issue}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Content Analysis */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-6">
              <FileText className="h-6 w-6 text-green-500 mr-2" />
              <h2 className="text-lg font-semibold text-gray-900">Content Analysis</h2>
            </div>

            <div className="space-y-6">
              {/* Headings Analysis */}
              <div className={`${getScoreBackground(analysis.headings.score)} rounded-lg p-4`}>
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-sm font-medium text-gray-900">Headings Structure</h3>
                  <span className={`text-sm font-semibold ${getScoreColor(analysis.headings.score)}`}>
                    {analysis.headings.score}%
                  </span>
                </div>
                <div className="grid grid-cols-3 gap-4 mb-4">
                  <div>
                    <p className="text-sm text-gray-600">H1 Tags</p>
                    <p className="text-lg font-semibold">{analysis.headings.h1Count}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-600">H2 Tags</p>
                    <p className="text-lg font-semibold">{analysis.headings.h2Count}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-600">H3 Tags</p>
                    <p className="text-lg font-semibold">{analysis.headings.h3Count}</p>
                  </div>
                </div>
                {analysis.headings.issues.map((issue, index) => (
                  <div key={index} className="flex items-center text-sm text-red-600">
                    <AlertTriangle className="h-4 w-4 mr-1" />
                    {issue}
                  </div>
                ))}
              </div>

              {/* Content Quality */}
              <div className={`${getScoreBackground(analysis.content.score)} rounded-lg p-4`}>
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-sm font-medium text-gray-900">Content Quality</h3>
                  <span className={`text-sm font-semibold ${getScoreColor(analysis.content.score)}`}>
                    {analysis.content.score}%
                  </span>
                </div>
                <p className="text-sm text-gray-600 mb-2">
                  Word Count: {analysis.content.wordCount}
                </p>
                <p className="text-sm text-gray-600 mb-2">
                  Readability Score: {analysis.content.readabilityScore}%
                </p>
                {analysis.content.issues.map((issue, index) => (
                  <div key={index} className="flex items-center text-sm text-red-600">
                    <AlertTriangle className="h-4 w-4 mr-1" />
                    {issue}
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Technical Elements */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center mb-6">
              <LinkIcon className="h-6 w-6 text-purple-500 mr-2" />
              <h2 className="text-lg font-semibold text-gray-900">Technical Elements</h2>
            </div>

            <div className="space-y-6">
              {/* Images Analysis */}
              <div className={`${getScoreBackground(analysis.images.score)} rounded-lg p-4`}>
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-sm font-medium text-gray-900">Images</h3>
                  <span className={`text-sm font-semibold ${getScoreColor(analysis.images.score)}`}>
                    {analysis.images.score}%
                  </span>
                </div>
                <div className="grid grid-cols-3 gap-4 mb-4">
                  <div>
                    <p className="text-sm text-gray-600">Total Images</p>
                    <p className="text-lg font-semibold">{analysis.images.total}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-600">With Alt Text</p>
                    <p className="text-lg font-semibold">{analysis.images.withAlt}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-600">Missing Alt Text</p>
                    <p className="text-lg font-semibold">{analysis.images.withoutAlt}</p>
                  </div>
                </div>
                {analysis.images.issues.map((issue, index) => (
                  <div key={index} className="flex items-center text-sm text-red-600">
                    <AlertTriangle className="h-4 w-4 mr-1" />
                    {issue}
                  </div>
                ))}
              </div>

              {/* Internal Links Analysis */}
              <div className={`${getScoreBackground(analysis.internalLinks.score)} rounded-lg p-4`}>
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-sm font-medium text-gray-900">Internal Links</h3>
                  <span className={`text-sm font-semibold ${getScoreColor(analysis.internalLinks.score)}`}>
                    {analysis.internalLinks.score}%
                  </span>
                </div>
                <p className="text-sm text-gray-600 mb-2">
                  Total Internal Links: {analysis.internalLinks.count}
                </p>
                {analysis.internalLinks.issues.map((issue, index) => (
                  <div key={index} className="flex items-center text-sm text-red-600">
                    <AlertTriangle className="h-4 w-4 mr-1" />
                    {issue}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnPageSEO;