import React, { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import { PageSpeedTab } from './PageSpeedTab';
import { ContentAnalysisTab } from './ContentAnalysisTab';
import { OffPageSEOTab } from './OffPageSEOTab';
import { KeywordsTab } from './KeywordsTab';
import { CompetitorAnalysisTab } from './CompetitorAnalysisTab';
import { Website } from '@/lib/types';
import { Globe, Zap, FileText, Link, Search, Users } from 'lucide-react';

interface DetailedAuditViewProps {
  website: Website;
}

export const DetailedAuditView: React.FC<DetailedAuditViewProps> = ({ website }) => {
  const [activeTab, setActiveTab] = useState('overview');
  const audit = website.seo_audits?.[0];

  if (!audit) return null;

  return (
    <div className="space-y-6">
      {/* Score Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-lg p-6 text-white">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-blue-100">Overall Score</p>
              <h2 className="text-4xl font-bold">{audit.score}%</h2>
            </div>
            <Globe className="h-12 w-12 opacity-50" />
          </div>
        </div>

        <div className="bg-gradient-to-br from-green-500 to-green-600 rounded-lg p-6 text-white">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-green-100">Performance</p>
              <h2 className="text-4xl font-bold">{audit.page_speed_score}%</h2>
            </div>
            <Zap className="h-12 w-12 opacity-50" />
          </div>
        </div>

        <div className="bg-gradient-to-br from-purple-500 to-purple-600 rounded-lg p-6 text-white">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-purple-100">SEO Health</p>
              <h2 className="text-4xl font-bold">{audit.meta_title_score}%</h2>
            </div>
            <Search className="h-12 w-12 opacity-50" />
          </div>
        </div>
      </div>

      {/* Detailed Analysis Tabs */}
      <Tabs defaultValue={activeTab} onValueChange={setActiveTab} className="space-y-4">
        <TabsList className="grid grid-cols-2 lg:grid-cols-6 gap-4">
          <TabsTrigger value="overview" className="flex items-center gap-2">
            <Globe className="h-4 w-4" />
            Overview
          </TabsTrigger>
          <TabsTrigger value="pagespeed" className="flex items-center gap-2">
            <Zap className="h-4 w-4" />
            PageSpeed
          </TabsTrigger>
          <TabsTrigger value="content" className="flex items-center gap-2">
            <FileText className="h-4 w-4" />
            Content
          </TabsTrigger>
          <TabsTrigger value="offpage" className="flex items-center gap-2">
            <Link className="h-4 w-4" />
            Off-Page
          </TabsTrigger>
          <TabsTrigger value="keywords" className="flex items-center gap-2">
            <Search className="h-4 w-4" />
            Keywords
          </TabsTrigger>
          <TabsTrigger value="competitors" className="flex items-center gap-2">
            <Users className="h-4 w-4" />
            Competitors
          </TabsTrigger>
        </TabsList>

        <TabsContent value="overview" className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Technical Issues */}
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Technical Issues</h3>
              <div className="space-y-3">
                {audit.issues.map((issue: any, index: number) => (
                  <div key={index} className="flex items-start gap-2 text-sm text-gray-600">
                    <span className="w-2 h-2 mt-1.5 rounded-full bg-red-500" />
                    <span>{issue.message}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Recommendations */}
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Recommendations</h3>
              <div className="space-y-3">
                {audit.recommendations.map((rec: any, index: number) => (
                  <div key={index} className="flex items-start gap-2 text-sm text-gray-600">
                    <span className="w-2 h-2 mt-1.5 rounded-full bg-green-500" />
                    <span>{rec.message}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </TabsContent>

        <TabsContent value="pagespeed">
          <PageSpeedTab metrics={audit.performance_metrics} />
        </TabsContent>

        <TabsContent value="content">
          <ContentAnalysisTab analysis={audit.content_analysis} />
        </TabsContent>

        <TabsContent value="offpage">
          <OffPageSEOTab
            backlinks={{
              count: 150,
              domains: 45,
              quality: 85,
              data: []
            }}
            socialMetrics={{
              shares: 1200,
              likes: 3500,
              comments: 800,
              platforms: {
                facebook: 500,
                twitter: 300,
                linkedin: 400
              }
            }}
          />
        </TabsContent>

        <TabsContent value="keywords">
          <KeywordsTab
            keywords={[
              {
                keyword: "seo tools",
                position: 5,
                previousPosition: 8,
                volume: 12000,
                difficulty: 65,
                intent: "transactional"
              },
              // Add more keywords as needed
            ]}
          />
        </TabsContent>

        <TabsContent value="competitors">
          <CompetitorAnalysisTab
            competitors={[
              {
                domain: "competitor1.com",
                metrics: {
                  authority: 45,
                  traffic: 50000,
                  keywords: 1500,
                  backlinks: 2000
                },
                commonKeywords: [],
                backlinks: []
              },
              // Add more competitors as needed
            ]}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};