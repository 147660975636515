import React from 'react';
import { Search, TrendingUp, ArrowUp, ArrowDown } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface Keyword {
  keyword: string;
  position: number;
  previousPosition: number;
  volume: number;
  difficulty: number;
  intent: 'informational' | 'transactional' | 'navigational';
}

interface KeywordsTabProps {
  keywords: Keyword[];
  rankings?: Array<{
    date: string;
    averagePosition: number;
    top10: number;
    top100: number;
  }>;
}

export const KeywordsTab: React.FC<KeywordsTabProps> = ({ keywords, rankings = [] }) => {
  const getPositionChange = (current: number, previous: number) => {
    const change = previous - current;
    if (change > 0) {
      return <span className="text-green-500 flex items-center"><ArrowUp className="h-4 w-4 mr-1" />{change}</span>;
    }
    if (change < 0) {
      return <span className="text-red-500 flex items-center"><ArrowDown className="h-4 w-4 mr-1" />{Math.abs(change)}</span>;
    }
    return <span className="text-gray-500">-</span>;
  };

  const getIntentColor = (intent: string) => {
    switch (intent) {
      case 'informational':
        return 'bg-blue-100 text-blue-800';
      case 'transactional':
        return 'bg-green-100 text-green-800';
      case 'navigational':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="space-y-8">
      {/* Rankings Overview */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Rankings Overview</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-gray-50 rounded-lg p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Search className="h-5 w-5 text-blue-500 mr-2" />
                <span className="text-sm text-gray-700">Tracked Keywords</span>
              </div>
              <span className="text-lg font-semibold">{keywords.length}</span>
            </div>
          </div>
          <div className="bg-gray-50 rounded-lg p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <TrendingUp className="h-5 w-5 text-green-500 mr-2" />
                <span className="text-sm text-gray-700">Top 10 Rankings</span>
              </div>
              <span className="text-lg font-semibold">
                {keywords.filter(k => k.position <= 10).length}
              </span>
            </div>
          </div>
          <div className="bg-gray-50 rounded-lg p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Search className="h-5 w-5 text-purple-500 mr-2" />
                <span className="text-sm text-gray-700">Average Position</span>
              </div>
              <span className="text-lg font-semibold">
                {(keywords.reduce((acc, k) => acc + k.position, 0) / keywords.length).toFixed(1)}
              </span>
            </div>
          </div>
        </div>

        {/* Rankings Chart */}
        {rankings.length > 0 && (
          <div className="mt-6 h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={rankings}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="averagePosition"
                  name="Avg Position"
                  stroke="#3B82F6"
                  strokeWidth={2}
                />
                <Line
                  type="monotone"
                  dataKey="top10"
                  name="Top 10"
                  stroke="#10B981"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>

      {/* Keywords Table */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">Keyword Rankings</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Keyword
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Position
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Change
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Volume
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Difficulty
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Intent
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {keywords.map((keyword, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="text-sm font-medium text-gray-900">{keyword.keyword}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="text-sm text-gray-500">{keyword.position}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {getPositionChange(keyword.position, keyword.previousPosition)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="text-sm text-gray-500">{keyword.volume.toLocaleString()}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="w-24 bg-gray-200 rounded-full h-2">
                      <div
                        className="bg-blue-600 rounded-full h-2"
                        style={{ width: `${keyword.difficulty}%` }}
                      />
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 py-1 text-xs font-medium rounded-full ${getIntentColor(keyword.intent)}`}>
                      {keyword.intent}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};