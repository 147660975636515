import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Globe, TrendingUp, Link as LinkIcon, Users, Search, BarChart2 } from 'lucide-react';
import { Button } from '../components/ui/button';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar
} from 'recharts';

interface Competitor {
  domain: string;
  metrics: {
    authority: number;
    traffic: number;
    keywords: number;
    backlinks: number;
  };
  commonKeywords: Array<{
    keyword: string;
    ourPosition: number;
    theirPosition: number;
    volume: number;
    difficulty: number;
  }>;
  backlinks: Array<{
    domain: string;
    authority: number;
    anchor: string;
  }>;
}

const CompetitorAnalysis = () => {
  const navigate = useNavigate();
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [competitors, setCompetitors] = useState<Competitor[]>([]);

  const analyzeUrl = async () => {
    try {
      setLoading(true);
      setError(null);

      // Validate URL format
      let validUrl: string;
      try {
        const urlObj = new URL(url);
        validUrl = urlObj.toString();
      } catch {
        throw new Error('Please enter a valid URL');
      }

      // TODO: Implement competitor analysis
      // This will be connected to the backend service later
      setCompetitors([]);
    } catch (err) {
      console.error('Error analyzing URL:', err);
      setError(err instanceof Error ? err.message : 'Failed to analyze URL');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <div>
          <Button
            variant="ghost"
            onClick={() => navigate('/dashboard')}
            className="mb-4"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Dashboard
          </Button>
          <h1 className="text-2xl font-bold text-gray-900">Competitor Analysis</h1>
          <p className="text-gray-500 mt-2">
            Analyze and compare your website with competitors
          </p>
        </div>
      </div>

      {/* URL Input */}
      <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
        <div className="max-w-3xl mx-auto">
          <div className="flex gap-4">
            <input
              type="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter website URL (e.g., https://example.com)"
              className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <Button
              onClick={analyzeUrl}
              disabled={loading || !url}
              className="flex items-center"
            >
              {loading ? (
                <>
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                  Analyzing...
                </>
              ) : (
                <>
                  <Search className="h-4 w-4 mr-2" />
                  Analyze
                </>
              )}
            </Button>
          </div>
        </div>
      </div>

      {/* Analysis Sections */}
      <div className="space-y-8">
        {/* Competitor Overview */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center mb-6">
            <Users className="h-6 w-6 text-blue-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-900">Competitor Overview</h2>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Globe className="h-5 w-5 text-blue-500 mr-2" />
                  <span className="text-sm text-gray-700">Main Competitors</span>
                </div>
                <span className="text-lg font-semibold">N/A</span>
              </div>
            </div>
            
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <TrendingUp className="h-5 w-5 text-green-500 mr-2" />
                  <span className="text-sm text-gray-700">Market Share</span>
                </div>
                <span className="text-lg font-semibold">N/A</span>
              </div>
            </div>
            
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <LinkIcon className="h-5 w-5 text-purple-500 mr-2" />
                  <span className="text-sm text-gray-700">Common Keywords</span>
                </div>
                <span className="text-lg font-semibold">N/A</span>
              </div>
            </div>
            
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <BarChart2 className="h-5 w-5 text-orange-500 mr-2" />
                  <span className="text-sm text-gray-700">Traffic Share</span>
                </div>
                <span className="text-lg font-semibold">N/A</span>
              </div>
            </div>
          </div>
        </div>

        {/* Competitor Comparison */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center mb-6">
            <BarChart2 className="h-6 w-6 text-green-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-900">Competitor Comparison</h2>
          </div>
          
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={[]}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="domain" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="metrics.authority" name="Domain Authority" fill="#3B82F6" />
                <Bar dataKey="metrics.traffic" name="Traffic" fill="#10B981" />
                <Bar dataKey="metrics.keywords" name="Keywords" fill="#8B5CF6" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Keyword Gap Analysis */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center mb-6">
            <Search className="h-6 w-6 text-purple-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-900">Keyword Gap Analysis</h2>
          </div>
          
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Keyword
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Your Position
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Competitor Position
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Volume
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Difficulty
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" colSpan={5}>
                    Enter a URL above to analyze keyword gaps
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Backlink Comparison */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center mb-6">
            <LinkIcon className="h-6 w-6 text-orange-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-900">Backlink Comparison</h2>
          </div>
          
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <RadarChart data={[]}>
                <PolarGrid />
                <PolarAngleAxis dataKey="domain" />
                <PolarRadiusAxis />
                <Radar name="Backlinks" dataKey="metrics.backlinks" stroke="#3B82F6" fill="#3B82F6" fillOpacity={0.6} />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Content Strategy Insights */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center mb-6">
            <Globe className="h-6 w-6 text-blue-500 mr-2" />
            <h2 className="text-lg font-semibold text-gray-900">Content Strategy Insights</h2>
          </div>
          
          <p className="text-gray-500">
            Enter a URL above to get insights about your competitors' content strategies,
            including content types, publishing frequency, and engagement metrics.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CompetitorAnalysis;