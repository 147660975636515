import React from 'react';
import { FileText, Shield, Users, AlertTriangle } from 'lucide-react';

const Terms = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative bg-blue-600">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover opacity-10"
            src="https://images.unsplash.com/photo-1589829545856-d10d557cf95f?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
            alt="Terms of Service"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold text-white sm:text-5xl lg:text-6xl">
              Terms of Service
            </h1>
            <p className="mt-6 text-xl text-blue-100 max-w-3xl mx-auto">
              Please read these terms carefully before using our services.
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        {/* Key Points */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4 mb-16">
          {[
            {
              icon: FileText,
              title: "Agreement",
              description: "Legal binding terms between you and GetSEOExpert"
            },
            {
              icon: Shield,
              title: "Protection",
              description: "Your rights and responsibilities"
            },
            {
              icon: Users,
              title: "Usage",
              description: "How you can use our services"
            },
            {
              icon: AlertTriangle,
              title: "Compliance",
              description: "Rules and regulations to follow"
            }
          ].map((item, index) => {
            const Icon = item.icon;
            return (
              <div key={index} className="text-center">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white mx-auto">
                  <Icon className="h-6 w-6" />
                </div>
                <h3 className="mt-4 text-lg font-medium text-gray-900">{item.title}</h3>
                <p className="mt-2 text-base text-gray-500">{item.description}</p>
              </div>
            );
          })}
        </div>

        {/* Terms Sections */}
        <div className="prose prose-blue max-w-none">
          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Acceptance of Terms</h2>
            <p className="text-gray-600 mb-4">
              By accessing or using GetSEOExpert's services, you agree to be bound by these Terms of Service
              and all applicable laws and regulations. If you do not agree with any of these terms, you
              are prohibited from using or accessing our services.
            </p>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Description of Services</h2>
            <p className="text-gray-600 mb-4">
              GetSEOExpert provides SEO analysis and optimization tools, including but not limited to:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Website SEO analysis and recommendations</li>
              <li>Keyword research and tracking</li>
              <li>Technical SEO audits</li>
              <li>Performance monitoring</li>
              <li>Reporting and analytics</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">3. User Accounts</h2>
            <p className="text-gray-600 mb-4">
              To access our services, you must:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Create an account with accurate information</li>
              <li>Maintain the security of your account credentials</li>
              <li>Notify us immediately of any unauthorized access</li>
              <li>Be responsible for all activities under your account</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Payment Terms</h2>
            <p className="text-gray-600 mb-4">
              Our payment terms include:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Subscription fees are billed in advance</li>
              <li>Automatic renewal unless cancelled</li>
              <li>No refunds for partial month periods</li>
              <li>Price changes with 30 days notice</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Acceptable Use</h2>
            <p className="text-gray-600 mb-4">
              You agree not to:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Violate any laws or regulations</li>
              <li>Infringe on intellectual property rights</li>
              <li>Attempt to gain unauthorized access</li>
              <li>Use the service for malicious purposes</li>
              <li>Interfere with service operation</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Intellectual Property</h2>
            <p className="text-gray-600 mb-4">
              All content and materials available through our service are protected by:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Copyright laws</li>
              <li>Trademark rights</li>
              <li>Other proprietary rights</li>
              <li>Intellectual property laws</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Limitation of Liability</h2>
            <p className="text-gray-600 mb-4">
              GetSEOExpert shall not be liable for:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Indirect or consequential damages</li>
              <li>Loss of profits or data</li>
              <li>Service interruptions</li>
              <li>Third-party actions</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">8. Modifications to Service</h2>
            <p className="text-gray-600 mb-4">
              We reserve the right to:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Modify or discontinue services</li>
              <li>Update these terms</li>
              <li>Change pricing</li>
              <li>Add or remove features</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">9. Termination</h2>
            <p className="text-gray-600 mb-4">
              We may terminate or suspend your account if you:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Violate these terms</li>
              <li>Engage in fraudulent activity</li>
              <li>Fail to pay fees</li>
              <li>Abuse our services</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">10. Contact Information</h2>
            <div className="bg-gray-50 p-6 rounded-lg">
              <p className="text-gray-600">
                For questions about these terms, please contact us:<br /><br />
                Email: legal@getseoexpert.com<br />
                Address: 123 SEO Street, San Francisco, CA 94105<br />
                Phone: +1 (555) 123-4567
              </p>
            </div>
          </section>
        </div>

        {/* Last Updated */}
        <div className="mt-16 pt-8 border-t border-gray-200">
          <p className="text-sm text-gray-500 text-center">
            Last updated: March 31, 2025
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;