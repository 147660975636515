import React from 'react';
import { Briefcase, MapPin, DollarSign, Users, Star, Heart } from 'lucide-react';

const Careers = () => {
  const openPositions = [
    {
      title: "Senior SEO Specialist",
      department: "SEO Strategy",
      location: "San Francisco, CA",
      type: "Full-time",
      salary: "$120,000 - $150,000"
    },
    {
      title: "Full Stack Developer",
      department: "Engineering",
      location: "Remote",
      type: "Full-time",
      salary: "$130,000 - $160,000"
    },
    {
      title: "Product Manager",
      department: "Product",
      location: "New York, NY",
      type: "Full-time",
      salary: "$140,000 - $170,000"
    },
    {
      title: "Customer Success Manager",
      department: "Customer Success",
      location: "Remote",
      type: "Full-time",
      salary: "$80,000 - $100,000"
    }
  ];

  const benefits = [
    {
      icon: DollarSign,
      title: "Competitive Salary",
      description: "We offer top-market compensation packages"
    },
    {
      icon: Heart,
      title: "Health Benefits",
      description: "Comprehensive health, dental, and vision coverage"
    },
    {
      icon: Users,
      title: "Remote Work",
      description: "Flexible work arrangements and remote options"
    },
    {
      icon: Star,
      title: "Growth Opportunities",
      description: "Career development and learning programs"
    }
  ];

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative bg-blue-600">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover opacity-10"
            src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
            alt="Career opportunities"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold text-white sm:text-5xl lg:text-6xl">
              Join Our Team
            </h1>
            <p className="mt-6 text-xl text-blue-100 max-w-3xl mx-auto">
              Help us revolutionize the way businesses approach SEO. We're looking for passionate
              individuals who want to make a difference in the digital world.
            </p>
          </div>
        </div>
      </div>

      {/* Why Join Us Section */}
      <div className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Why Join GetSEOExpert?</h2>
            <p className="mt-4 text-lg text-gray-500">
              We're building the future of SEO tools while creating an amazing workplace
            </p>
          </div>

          <div className="mt-16">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
              {benefits.map((benefit, index) => {
                const Icon = benefit.icon;
                return (
                  <div key={index} className="bg-white rounded-lg shadow-lg p-6">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white mx-auto">
                      <Icon className="h-6 w-6" />
                    </div>
                    <h3 className="mt-4 text-lg font-medium text-gray-900 text-center">
                      {benefit.title}
                    </h3>
                    <p className="mt-2 text-base text-gray-500 text-center">
                      {benefit.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Open Positions */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Open Positions</h2>
            <p className="mt-4 text-lg text-gray-500">
              Find your next career opportunity with us
            </p>
          </div>

          <div className="mt-12">
            <div className="space-y-4">
              {openPositions.map((position, index) => (
                <div
                  key={index}
                  className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow"
                >
                  <div className="md:flex md:items-center md:justify-between">
                    <div className="md:flex-1">
                      <h3 className="text-xl font-semibold text-gray-900">
                        {position.title}
                      </h3>
                      <div className="mt-2 flex flex-wrap gap-4">
                        <div className="flex items-center text-sm text-gray-500">
                          <Briefcase className="h-4 w-4 mr-2" />
                          {position.department}
                        </div>
                        <div className="flex items-center text-sm text-gray-500">
                          <MapPin className="h-4 w-4 mr-2" />
                          {position.location}
                        </div>
                        <div className="flex items-center text-sm text-gray-500">
                          <DollarSign className="h-4 w-4 mr-2" />
                          {position.salary}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 md:mt-0">
                      <button className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700">
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Culture Section */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-extrabold text-gray-900">Our Culture</h2>
              <p className="mt-4 text-lg text-gray-500">
                At GetSEOExpert, we believe in creating an environment where everyone can thrive.
                Our culture is built on innovation, collaboration, and continuous learning.
              </p>
              <div className="mt-8 space-y-4">
                {[
                  "Work-life balance",
                  "Inclusive environment",
                  "Professional development",
                  "Innovation-driven",
                  "Global team"
                ].map((item, index) => (
                  <div key={index} className="flex items-center">
                    <Star className="h-5 w-5 text-blue-500 mr-2" />
                    <span className="text-gray-700">{item}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="relative h-96">
              <img
                src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
                alt="Team culture"
                className="absolute inset-0 w-full h-full object-cover rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Application Process */}
      <div className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Application Process</h2>
            <p className="mt-4 text-lg text-gray-500">
              Our hiring process is designed to be transparent and efficient
            </p>
          </div>

          <div className="mt-12">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              {[
                {
                  step: "1",
                  title: "Application",
                  description: "Submit your resume and cover letter"
                },
                {
                  step: "2",
                  title: "Initial Interview",
                  description: "Chat with our hiring team"
                },
                {
                  step: "3",
                  title: "Technical Assessment",
                  description: "Show us your skills"
                },
                {
                  step: "4",
                  title: "Final Interview",
                  description: "Meet the team"
                }
              ].map((step, index) => (
                <div key={index} className="text-center">
                  <div className="flex items-center justify-center h-12 w-12 rounded-full bg-blue-600 text-white mx-auto">
                    <span className="text-lg font-semibold">{step.step}</span>
                  </div>
                  <h3 className="mt-4 text-lg font-medium text-gray-900">{step.title}</h3>
                  <p className="mt-2 text-base text-gray-500">{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;