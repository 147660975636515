import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Search, BarChart2, Settings } from 'lucide-react';
import { Button } from './ui/button';
import { useAuthStore } from '../lib/auth-store';
import { supabase } from '../lib/supabase';

const Navbar = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuthStore();
  const [isAdmin, setIsAdmin] = React.useState(false);

  React.useEffect(() => {
    if (user) {
      checkAdminStatus();
    }
  }, [user]);

  const checkAdminStatus = async () => {
    try {
      const { data, error } = await supabase
        .from('admin_users')
        .select('id')
        .eq('id', user?.id)
        .maybeSingle();

      // Set isAdmin to true only if we found a matching admin user
      setIsAdmin(!!data);
    } catch (error) {
      console.error('Error checking admin status:', error);
      setIsAdmin(false);
    }
  };

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <Link to="/" className="flex items-center">
              <Search className="h-8 w-8 text-blue-600" />
              <span className="ml-2 text-xl font-bold text-gray-900">SeoExpert</span>
            </Link>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              <Link
                to="/features"
                className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 hover:text-gray-900"
              >
                Features
              </Link>
              <Link
                to="/pricing"
                className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 hover:text-gray-900"
              >
                Pricing
              </Link>
              <Link
                to="/blog"
                className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 hover:text-gray-900"
              >
                Blog
              </Link>
            </div>
          </div>
          <div className="flex items-center">
            {user ? (
              <>
                <Link to="/dashboard">
                  <Button variant="ghost" className="mr-2">
                    Dashboard
                  </Button>
                </Link>
                {isAdmin && (
                  <>
                    <Link to="/admin">
                      <Button variant="ghost" className="mr-2">
                        Admin
                      </Button>
                    </Link>
                    <Link to="/super-admin">
                      <Button variant="ghost" className="mr-2">
                        Super Admin
                      </Button>
                    </Link>
                  </>
                )}
                <Button onClick={handleSignOut} variant="ghost">
                  Sign out
                </Button>
              </>
            ) : (
              <>
                <Link to="/login">
                  <Button variant="ghost" className="mr-2">
                    Log in
                  </Button>
                </Link>
                <Link to="/register">
                  <Button>Get Started</Button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;