import React, { useState } from 'react';
import { Calendar, User, Clock, ArrowRight, Search, Tag, BookOpen } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Button } from '../components/ui/button';

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  content: string;
  image: string;
  author: {
    name: string;
    avatar: string;
    role: string;
  };
  date: string;
  readTime: string;
  category: string;
  tags: string[];
}

const blogPosts: BlogPost[] = [
  {
    id: "1",
    title: "The Ultimate Guide to Technical SEO in 2025",
    excerpt: "Master the technical aspects of SEO with our comprehensive guide covering everything from site architecture to Core Web Vitals.",
    content: "Technical SEO forms the foundation of any successful SEO strategy...",
    image: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    author: {
      name: "Michael Chen",
      avatar: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80",
      role: "Technical SEO Specialist"
    },
    date: "March 15, 2025",
    readTime: "15 min read",
    category: "Technical SEO",
    tags: ["Core Web Vitals", "Site Speed", "Mobile SEO"]
  },
  {
    id: "2",
    title: "AI-Powered Content Optimization Strategies",
    excerpt: "Learn how to leverage artificial intelligence to create and optimize content that ranks higher in search results.",
    image: "https://images.unsplash.com/photo-1485796826113-174aa68fd81b?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    content: "Artificial intelligence is revolutionizing content optimization...",
    author: {
      name: "Sarah Johnson",
      avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80",
      role: "Content Strategy Director"
    },
    date: "March 12, 2025",
    readTime: "12 min read",
    category: "Content Strategy",
    tags: ["AI", "Content Optimization", "Machine Learning"]
  },
  {
    id: "3",
    title: "Local SEO: Dominate Your Market in 2025",
    excerpt: "Discover the latest strategies and tools for improving your local search presence and attracting more customers.",
    image: "https://images.unsplash.com/photo-1523540939399-141cbff6a8d7?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    content: "Local SEO is more important than ever for businesses...",
    author: {
      name: "Emma Davis",
      avatar: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80",
      role: "Local SEO Expert"
    },
    date: "March 10, 2025",
    readTime: "10 min read",
    category: "Local SEO",
    tags: ["Google Business Profile", "Local Search", "Citations"]
  },
  {
    id: "4",
    title: "E-commerce SEO: Boost Your Online Store's Rankings",
    excerpt: "Learn the essential SEO strategies specifically designed for e-commerce websites to increase visibility and sales.",
    image: "https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    content: "E-commerce SEO requires a unique approach...",
    author: {
      name: "Alex Turner",
      avatar: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80",
      role: "E-commerce SEO Consultant"
    },
    date: "March 8, 2025",
    readTime: "14 min read",
    category: "E-commerce",
    tags: ["Online Store", "Product Pages", "Schema Markup"]
  },
  {
    id: "5",
    title: "Mobile-First Indexing: A Complete Guide",
    excerpt: "Everything you need to know about optimizing your website for mobile-first indexing and improving mobile rankings.",
    image: "https://images.unsplash.com/photo-1522125670776-3c7b9771949c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    content: "Mobile optimization is no longer optional...",
    author: {
      name: "David Wilson",
      avatar: "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80",
      role: "Mobile SEO Specialist"
    },
    date: "March 5, 2025",
    readTime: "11 min read",
    category: "Technical SEO",
    tags: ["Mobile SEO", "Responsive Design", "Page Speed"]
  },
  {
    id: "6",
    title: "Link Building Strategies That Actually Work",
    excerpt: "Discover effective and white-hat link building techniques to improve your website's authority and rankings.",
    image: "https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    content: "Quality backlinks remain a crucial ranking factor...",
    author: {
      name: "Lisa Brown",
      avatar: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80",
      role: "Link Building Expert"
    },
    date: "March 3, 2025",
    readTime: "13 min read",
    category: "Link Building",
    tags: ["Backlinks", "Outreach", "Digital PR"]
  }
];

const categories = [
  "All",
  "Technical SEO",
  "Content Strategy",
  "Local SEO",
  "E-commerce",
  "Link Building",
  "Analytics"
];

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const filteredPosts = blogPosts.filter(post => {
    const matchesCategory = selectedCategory === "All" || post.category === selectedCategory;
    const matchesSearch = post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         post.excerpt.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative bg-blue-600">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover opacity-10"
            src="https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
            alt="Blog Header"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold text-white sm:text-5xl lg:text-6xl">
              SEO Insights & Updates
            </h1>
            <p className="mt-4 text-xl text-blue-100">
              Stay up to date with the latest SEO trends, strategies, and best practices
            </p>
          </div>
        </div>
      </div>

      {/* Search and Filter Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div className="relative w-full md:w-96">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="Search articles..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          <div className="flex flex-wrap gap-2">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 rounded-full text-sm font-medium ${
                  selectedCategory === category
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Featured Post */}
      {filteredPosts.length > 0 && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="relative h-96 rounded-xl overflow-hidden">
            <img
              src={filteredPosts[0].image}
              alt={filteredPosts[0].title}
              className="absolute inset-0 w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/75 to-transparent" />
            <div className="absolute bottom-0 left-0 right-0 p-8">
              <span className="inline-block px-3 py-1 bg-blue-600 text-white text-sm font-medium rounded-full mb-4">
                {filteredPosts[0].category}
              </span>
              <h2 className="text-3xl font-bold text-white mb-4">
                {filteredPosts[0].title}
              </h2>
              <div className="flex items-center text-white">
                <img
                  src={filteredPosts[0].author.avatar}
                  alt={filteredPosts[0].author.name}
                  className="h-10 w-10 rounded-full mr-4"
                />
                <div>
                  <p className="font-medium">{filteredPosts[0].author.name}</p>
                  <div className="flex items-center text-sm">
                    <Calendar className="h-4 w-4 mr-1" />
                    {filteredPosts[0].date}
                    <Clock className="h-4 w-4 ml-4 mr-1" />
                    {filteredPosts[0].readTime}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Blog Posts Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredPosts.slice(1).map((post) => (
            <article key={post.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="relative h-48">
                <img
                  src={post.image}
                  alt={post.title}
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
              <div className="p-6">
                <div className="flex items-center mb-4">
                  <span className="px-3 py-1 bg-blue-100 text-blue-600 text-sm font-medium rounded-full">
                    {post.category}
                  </span>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">
                  {post.title}
                </h3>
                <p className="text-gray-600 mb-4">
                  {post.excerpt}
                </p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img
                      src={post.author.avatar}
                      alt={post.author.name}
                      className="h-8 w-8 rounded-full mr-2"
                    />
                    <div className="text-sm">
                      <p className="font-medium text-gray-900">{post.author.name}</p>
                      <p className="text-gray-500">{post.date}</p>
                    </div>
                  </div>
                  <div className="flex items-center text-sm text-gray-500">
                    <Clock className="h-4 w-4 mr-1" />
                    {post.readTime}
                  </div>
                </div>
                <div className="mt-4 flex flex-wrap gap-2">
                  {post.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 bg-gray-100 text-gray-600 text-xs rounded-full"
                    >
                      #{tag}
                    </span>
                  ))}
                </div>
                <Button className="w-full mt-4">
                  Read More
                  <ArrowRight className="ml-2 h-4 w-4" />
                </Button>
              </div>
            </article>
          ))}
        </div>
      </div>

      {/* Newsletter Section */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900">
              Subscribe to Our Newsletter
            </h2>
            <p className="mt-4 text-lg text-gray-600">
              Get the latest SEO tips and strategies delivered to your inbox
            </p>
            <div className="mt-8 max-w-md mx-auto">
              <div className="flex gap-4">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
                <Button>Subscribe</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;