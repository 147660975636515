import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthStore } from '../lib/auth-store';
import { supabase } from '../lib/supabase';

interface AuthGuardProps {
  children: React.ReactNode;
}

const AuthGuard = ({ children }: AuthGuardProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loading } = useAuthStore();

  useEffect(() => {
    const checkAccess = async () => {
      if (!loading && !user) {
        navigate('/login');
        return;
      }

      // Check for super admin routes
      if (location.pathname.startsWith('/super-admin')) {
        try {
          const { data: adminData, error: adminError } = await supabase
            .from('admin_users')
            .select('id')
            .eq('id', user?.id)
            .single();

          if (adminError || !adminData) {
            navigate('/super-admin-login');
            return;
          }
        } catch (error) {
          console.error('Error checking admin access:', error);
          navigate('/super-admin-login');
          return;
        }
      }
    };

    checkAccess();
  }, [user, loading, navigate, location]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return user ? <>{children}</> : null;
};

export default AuthGuard;