import React from 'react';
import { Shield, Zap, Star } from 'lucide-react';
import { Button } from '../components/ui/button';
import { useAuthStore } from '../lib/auth-store';
import { useNavigate } from 'react-router-dom';

const plans = [
  {
    name: 'Basic',
    price: '$9',
    priceId: 'price_basic',
    description: 'Perfect for small websites',
    features: [
      '3 websites',
      'Weekly audits',
      'Basic SEO recommendations',
      'Email support',
      'Performance monitoring',
      'Mobile optimization checks'
    ],
    color: 'blue'
  },
  {
    name: 'Pro',
    price: '$49',
    priceId: 'price_pro',
    description: 'Best for growing businesses',
    features: [
      '10 websites',
      'Daily audits',
      'Advanced SEO analysis',
      'Priority support',
      'Custom reports',
      'Competitor analysis',
      'Keyword tracking',
      'Technical SEO tools'
    ],
    popular: true,
    color: 'purple'
  },
  {
    name: 'Enterprise',
    price: '$99',
    priceId: 'price_enterprise',
    description: 'For large organizations',
    features: [
      'Unlimited websites',
      'Real-time monitoring',
      'API access',
      'AI-powered recommendations',
      'White-label reports',
      'Custom integrations',
      'Advanced analytics',
      'Team collaboration',
      'Priority feature requests',
      'Custom training sessions'
    ],
    color: 'green'
  }
];

const Pricing = () => {
  const { user } = useAuthStore();
  const navigate = useNavigate();

  const handleSelectPlan = (plan: any) => {
    if (!user) {
      navigate('/login');
      return;
    }

    // Create a serializable plan object without icon property
    const serializablePlan = {
      name: plan.name,
      price: plan.price,
      priceId: plan.priceId,
      description: plan.description,
      features: plan.features,
      color: plan.color,
      popular: plan.popular
    };

    navigate('/checkout', { state: { plan: serializablePlan } });
  };

  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
            Pricing Plans
          </h1>
          <p className="mt-5 text-xl text-gray-500 sm:text-center">
            Choose the perfect plan for your business
          </p>
        </div>

        <div className="mt-16 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
          {plans.map((plan) => {
            const Icon = plan.name === 'Basic' ? Shield : plan.name === 'Pro' ? Zap : Star;
            return (
              <div
                key={plan.name}
                className={`relative p-8 bg-white border rounded-2xl shadow-lg flex flex-col
                  ${plan.popular ? 'ring-2 ring-blue-500' : ''}`}
              >
                {plan.popular && (
                  <div className="absolute top-0 right-6 -mt-4">
                    <span className="inline-flex items-center px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-blue-100 text-blue-600">
                      Most Popular
                    </span>
                  </div>
                )}

                <div className="flex items-center justify-between">
                  <h3 className="text-2xl font-semibold text-gray-900">{plan.name}</h3>
                  <Icon className={`h-8 w-8 text-${plan.color}-500`} />
                </div>

                <p className="mt-4 text-gray-500">{plan.description}</p>

                <div className="mt-6">
                  <p className="text-5xl font-extrabold text-gray-900">{plan.price}</p>
                  <p className="mt-1 text-gray-500">/month</p>
                </div>

                <hr className="mt-8 border-gray-200" />

                <ul className="mt-8 space-y-4">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-start">
                      <div className="flex-shrink-0">
                        <svg className={`h-6 w-6 text-${plan.color}-500`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p className="ml-3 text-gray-500">{feature}</p>
                    </li>
                  ))}
                </ul>

                <div className="mt-8">
                  <Button
                    onClick={() => handleSelectPlan(plan)}
                    className={`w-full py-4 ${
                      plan.popular
                        ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                        : 'bg-gray-800 hover:bg-gray-900 focus:ring-gray-500'
                    }`}
                  >
                    {user ? 'Select Plan' : 'Sign Up'}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-16">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">
              Frequently asked questions
            </h2>
            <div className="mt-12 grid gap-8 lg:grid-cols-2">
              <div className="text-left">
                <h3 className="text-lg font-medium text-gray-900">
                  Can I switch plans later?
                </h3>
                <p className="mt-2 text-gray-500">
                  Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle.
                </p>
              </div>
              <div className="text-left">
                <h3 className="text-lg font-medium text-gray-900">
                  Is there a free trial?
                </h3>
                <p className="mt-2 text-gray-500">
                  Yes, all plans come with a 14-day free trial. No credit card required to start.
                </p>
              </div>
              <div className="text-left">
                <h3 className="text-lg font-medium text-gray-900">
                  What payment methods do you accept?
                </h3>
                <p className="mt-2 text-gray-500">
                  We accept all major credit cards, including Visa, Mastercard, and American Express.
                </p>
              </div>
              <div className="text-left">
                <h3 className="text-lg font-medium text-gray-900">
                  Can I cancel anytime?
                </h3>
                <p className="mt-2 text-gray-500">
                  Yes, you can cancel your subscription at any time. You'll continue to have access until the end of your billing period.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;