import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  BarChart2, ArrowLeft, Calendar, Globe, MousePointer, 
  Smartphone, Monitor, Search, TrendingUp, Map, AlertTriangle
} from 'lucide-react';
import { Button } from '../components/ui/button';
import { supabase } from '../lib/supabase';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar
} from 'recharts';

const SearchConsoleAnalytics = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [analytics, setAnalytics] = useState<any>(null);
  const [coverage, setCoverage] = useState<any>(null);
  const [dateRange, setDateRange] = useState('last28');
  const [selectedWebsite, setSelectedWebsite] = useState<string>('');
  const [websites, setWebsites] = useState<any[]>([]);

  useEffect(() => {
    loadWebsites();
  }, []);

  useEffect(() => {
    if (selectedWebsite) {
      loadSearchConsoleData();
    }
  }, [dateRange, selectedWebsite]);

  const loadWebsites = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      const { data: websiteData, error: websiteError } = await supabase
        .from('websites')
        .select('*')
        .eq('user_id', user.id);

      if (websiteError) throw websiteError;

      setWebsites(websiteData || []);
      if (websiteData?.length > 0) {
        setSelectedWebsite(new URL(websiteData[0].url).hostname);
      }
    } catch (err) {
      console.error('Error loading websites:', err);
      setError(err instanceof Error ? err.message : 'Failed to load websites');
    }
  };

  const loadSearchConsoleData = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await fetch('/api/search-console/analytics', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dateRange,
          websiteUrl: selectedWebsite
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch analytics data');
      }

      const data = await response.json();
      setAnalytics(data.analytics);
      setCoverage(data.coverage);
    } catch (err) {
      console.error('Error loading Search Console data:', err);
      setError(err instanceof Error ? err.message : 'Failed to load data');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!websites.length) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center">
          <AlertTriangle className="h-12 w-12 text-yellow-500 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-gray-900 mb-2">No Websites Found</h2>
          <p className="text-gray-600 mb-4">
            Add a website to your dashboard to view Search Console analytics.
          </p>
          <Button onClick={() => navigate('/dashboard')}>
            Go to Dashboard
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <div>
          <Button
            variant="ghost"
            onClick={() => navigate('/dashboard')}
            className="mb-4"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Dashboard
          </Button>
          <h1 className="text-2xl font-bold text-gray-900">Search Console Analytics</h1>
          <p className="text-gray-500 mt-2">
            Analyze your website's search performance and visibility
          </p>
        </div>
        <div className="flex gap-4">
          <select
            value={selectedWebsite}
            onChange={(e) => setSelectedWebsite(e.target.value)}
            className="rounded-lg border border-gray-300 px-4 py-2"
          >
            {websites.map((website) => (
              <option key={website.id} value={new URL(website.url).hostname}>
                {new URL(website.url).hostname}
              </option>
            ))}
          </select>
          <div className="flex gap-2">
            <Button
              variant={dateRange === 'last7' ? 'default' : 'outline'}
              onClick={() => setDateRange('last7')}
            >
              Last 7 Days
            </Button>
            <Button
              variant={dateRange === 'last28' ? 'default' : 'outline'}
              onClick={() => setDateRange('last28')}
            >
              Last 28 Days
            </Button>
            <Button
              variant={dateRange === 'last90' ? 'default' : 'outline'}
              onClick={() => setDateRange('last90')}
            >
              Last 90 Days
            </Button>
          </div>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded mb-8">
          {error}
        </div>
      )}

      {analytics && (
        <div className="space-y-8">
          {/* Performance Overview */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <MousePointer className="h-6 w-6 text-blue-500" />
                  <h3 className="ml-2 text-sm font-medium text-gray-900">Total Clicks</h3>
                </div>
                <span className="text-2xl font-bold text-gray-900">{analytics.totals.clicks}</span>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <Globe className="h-6 w-6 text-green-500" />
                  <h3 className="ml-2 text-sm font-medium text-gray-900">Impressions</h3>
                </div>
                <span className="text-2xl font-bold text-gray-900">{analytics.totals.impressions}</span>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <BarChart2 className="h-6 w-6 text-purple-500" />
                  <h3 className="ml-2 text-sm font-medium text-gray-900">Average CTR</h3>
                </div>
                <span className="text-2xl font-bold text-gray-900">
                  {(analytics.totals.ctr * 100).toFixed(1)}%
                </span>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <TrendingUp className="h-6 w-6 text-orange-500" />
                  <h3 className="ml-2 text-sm font-medium text-gray-900">Average Position</h3>
                </div>
                <span className="text-2xl font-bold text-gray-900">
                  {analytics.totals.position.toFixed(1)}
                </span>
              </div>
            </div>
          </div>

          {/* Performance Trend */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-6">Performance Trend</h2>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={analytics.performance}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis yAxisId="left" />
                  <YAxis yAxisId="right" orientation="right" />
                  <Tooltip />
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="clicks"
                    stroke="#3B82F6"
                    name="Clicks"
                  />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="impressions"
                    stroke="#10B981"
                    name="Impressions"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Top Queries */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-6">Top Queries</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Query
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Clicks
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Impressions
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      CTR
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Position
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {analytics.queries.map((query: any, index: number) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {query.query}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {query.clicks}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {query.impressions}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {(query.ctr * 100).toFixed(1)}%
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {query.position.toFixed(1)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Device & Country Distribution */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-6">Device Distribution</h2>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={analytics.devices}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="device" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="clicks" name="Clicks" fill="#3B82F6" />
                    <Bar dataKey="impressions" name="Impressions" fill="#10B981" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-6">Top Countries</h2>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={analytics.countries}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="country" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="clicks" name="Clicks" fill="#3B82F6" />
                    <Bar dataKey="impressions" name="Impressions" fill="#10B981" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          {/* Index Coverage */}
          {coverage && (
            <div className="bg-white rounded-lg shadow-lg p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-6">Index Coverage</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                <div className="bg-gray-50 rounded-lg p-4">
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-500">Indexed Pages</span>
                    <span className="text-lg font-semibold text-green-600">{coverage.indexed}</span>
                  </div>
                </div>
                <div className="bg-gray-50 rounded-lg p-4">
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-500">Excluded Pages</span>
                    <span className="text-lg font-semibold text-yellow-600">{coverage.excluded}</span>
                  </div>
                </div>
                <div className="bg-gray-50 rounded-lg p-4">
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-500">Errors</span>
                    <span className="text-lg font-semibold text-red-600">{coverage.errors.length}</span>
                  </div>
                </div>
                <div className="bg-gray-50 rounded-lg p-4">
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-500">Crawled Pages</span>
                    <span className="text-lg font-semibold text-blue-600">{coverage.crawled}</span>
                  </div>
                </div>
              </div>

              {coverage.errors.length > 0 && (
                <div className="mt-6">
                  <h3 className="text-md font-medium text-gray-900 mb-4">Indexing Errors</h3>
                  <div className="space-y-4">
                    {coverage.errors.map((error: any, index: number) => (
                      <div key={index} className="bg-red-50 rounded-lg p-4">
                        <div className="flex items-center justify-between mb-2">
                          <span className="font-medium text-red-700">{error.type}</span>
                          <span className="text-sm text-red-600">
                            Last Crawled: {new Date(error.lastCrawl).toLocaleDateString()}
                          </span>
                        </div>
                        <p className="text-sm text-red-600">{error.message}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchConsoleAnalytics;