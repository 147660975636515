import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Globe, ArrowLeft } from 'lucide-react';
import { Button } from '../components/ui/button';
import { DetailedAuditView } from '../components/seo/DetailedAuditView';
import { useAuthStore } from '../lib/auth-store';
import { supabase } from '../lib/supabase';
import type { Website } from '../lib/types';

const WebsiteAnalysis = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [website, setWebsite] = React.useState<Website | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    loadWebsiteData();
  }, [id]);

  const loadWebsiteData = async () => {
    try {
      if (!id || !user) return;

      const { data, error: fetchError } = await supabase
        .from('websites')
        .select(`
          *,
          seo_audits (*)
        `)
        .eq('id', id)
        .eq('user_id', user.id)
        .single();

      if (fetchError) throw fetchError;
      setWebsite(data);
    } catch (err) {
      console.error('Error loading website:', err);
      setError('Failed to load website data');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error || !website) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Error</h2>
          <p className="text-gray-600 mb-4">{error || 'Website not found'}</p>
          <Button onClick={() => navigate('/dashboard')}>
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Dashboard
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <div>
          <Button
            variant="ghost"
            onClick={() => navigate('/dashboard')}
            className="mb-4"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Dashboard
          </Button>
          <div className="flex items-center">
            <Globe className="h-8 w-8 text-blue-600 mr-3" />
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                {new URL(website.url).hostname}
              </h1>
              <p className="text-gray-500">
                Last analyzed: {website.last_audit_at ? new Date(website.last_audit_at).toLocaleDateString() : 'Never'}
              </p>
            </div>
          </div>
        </div>
        <a
          href={website.url}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
        >
          Visit Website
          <ArrowLeft className="h-4 w-4 ml-2 rotate-[-135deg]" />
        </a>
      </div>

      {/* SEO Analysis */}
      <DetailedAuditView website={website} />
    </div>
  );
};

export default WebsiteAnalysis;