import React from 'react';
import { DollarSign, Clock, CheckCircle, HelpCircle } from 'lucide-react';

const RefundPolicy = () => {
  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative bg-blue-600">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover opacity-10"
            src="https://images.unsplash.com/photo-1554224155-6726b3ff858f?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
            alt="Refund Policy"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold text-white sm:text-5xl lg:text-6xl">
              Refund Policy
            </h1>
            <p className="mt-6 text-xl text-blue-100 max-w-3xl mx-auto">
              Our commitment to customer satisfaction and fair refund practices
            </p>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        {/* Key Points */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4 mb-16">
          {[
            {
              icon: DollarSign,
              title: "Money Back Guarantee",
              description: "14-day refund period for all new subscriptions"
            },
            {
              icon: Clock,
              title: "Quick Processing",
              description: "Refunds processed within 5-7 business days"
            },
            {
              icon: CheckCircle,
              title: "No Questions Asked",
              description: "Simple and straightforward refund process"
            },
            {
              icon: HelpCircle,
              title: "24/7 Support",
              description: "Always here to help with refund requests"
            }
          ].map((item, index) => {
            const Icon = item.icon;
            return (
              <div key={index} className="text-center">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white mx-auto">
                  <Icon className="h-6 w-6" />
                </div>
                <h3 className="mt-4 text-lg font-medium text-gray-900">{item.title}</h3>
                <p className="mt-2 text-base text-gray-500">{item.description}</p>
              </div>
            );
          })}
        </div>

        {/* Policy Sections */}
        <div className="prose prose-blue max-w-none">
          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Refund Eligibility</h2>
            <p className="text-gray-600 mb-4">
              We offer refunds under the following conditions:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Within 14 days of initial subscription purchase</li>
              <li>Account in good standing</li>
              <li>No violation of terms of service</li>
              <li>First-time refund requests only</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Refund Process</h2>
            <p className="text-gray-600 mb-4">
              To request a refund:
            </p>
            <ol className="list-decimal pl-6 text-gray-600">
              <li>Log into your account</li>
              <li>Navigate to billing settings</li>
              <li>Click on "Request Refund"</li>
              <li>Fill out the refund request form</li>
              <li>Submit your request</li>
            </ol>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Processing Time</h2>
            <p className="text-gray-600 mb-4">
              Our refund processing timeline:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Request review: 1-2 business days</li>
              <li>Approval process: 1-2 business days</li>
              <li>Bank processing: 3-5 business days</li>
              <li>Total time: 5-7 business days</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Non-Refundable Items</h2>
            <p className="text-gray-600 mb-4">
              The following are not eligible for refunds:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Partial month subscriptions</li>
              <li>Add-on services</li>
              <li>Custom development work</li>
              <li>After the 14-day period</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Special Circumstances</h2>
            <p className="text-gray-600 mb-4">
              We may consider refunds outside the standard policy for:
            </p>
            <ul className="list-disc pl-6 text-gray-600">
              <li>Technical issues preventing service use</li>
              <li>Billing errors</li>
              <li>Service unavailability</li>
              <li>Other exceptional circumstances</li>
            </ul>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Cancellation vs. Refund</h2>
            <div className="bg-gray-50 p-6 rounded-lg mb-4">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Cancellation</h3>
              <ul className="list-disc pl-6 text-gray-600">
                <li>Stop future billing</li>
                <li>Access until current period ends</li>
                <li>No money returned</li>
                <li>Can be done anytime</li>
              </ul>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Refund</h3>
              <ul className="list-disc pl-6 text-gray-600">
                <li>Money returned to original payment method</li>
                <li>Immediate service termination</li>
                <li>14-day eligibility period</li>
                <li>One-time courtesy</li>
              </ul>
            </div>
          </section>

          <section className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Contact Support</h2>
            <p className="text-gray-600 mb-4">
              For refund assistance, contact us through:
            </p>
            <div className="bg-gray-50 p-6 rounded-lg">
              <p className="text-gray-600">
                Email: support@getseoexpert.com<br />
                Phone: +1 (555) 123-4567<br />
                Live Chat: Available 24/7<br />
                Support Portal: help.getseoexpert.com
              </p>
            </div>
          </section>
        </div>

        {/* Last Updated */}
        <div className="mt-16 pt-8 border-t border-gray-200">
          <p className="text-sm text-gray-500 text-center">
            Last updated: March 31, 2025
          </p>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;