import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Zap, ArrowLeft, Globe, Clock, AlertTriangle, Smartphone, LampDesk as Desktop, BarChart2 } from 'lucide-react';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { supabase } from '../lib/supabase';
import { formatUrl, getApiKey } from '../lib/utils';

interface PageSpeedResult {
  loadingExperience?: {
    metrics: {
      FIRST_CONTENTFUL_PAINT_MS: {
        percentile: number;
      };
      FIRST_INPUT_DELAY_MS: {
        percentile: number;
      };
    };
  };
  lighthouseResult: {
    categories: {
      performance: {
        score: number;
      };
    };
    audits: {
      [key: string]: {
        score: number;
        displayValue?: string;
        description: string;
        title: string;
      };
    };
  };
}

const PageSpeedInsights = () => {
  const navigate = useNavigate();
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [results, setResults] = useState<PageSpeedResult | null>(null);
  const [activeTab, setActiveTab] = useState<'mobile' | 'desktop'>('mobile');

  const analyzeUrl = async () => {
    try {
      setLoading(true);
      setError(null);

      // Format and validate URL
      const formattedUrl = formatUrl(url);
      if (!formattedUrl) {
        throw new Error('Please enter a valid URL');
      }

      // Get API key
      const apiKey = await getApiKey(supabase, 'Google Search');
      if (!apiKey) {
        throw new Error('Google Search API key not found. Please contact the administrator.');
      }

      // Call PageSpeed API
      const response = await fetch(
        `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(formattedUrl)}&key=${apiKey}&strategy=${activeTab}`
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error?.message || `API request failed: ${response.statusText}`);
      }

      const data = await response.json();
      setResults(data);
    } catch (err) {
      console.error('Error analyzing URL:', err);
      setError(err instanceof Error ? err.message : 'Failed to analyze URL');
    } finally {
      setLoading(false);
    }
  };

  const getScoreColor = (score: number) => {
    if (score >= 0.9) return 'text-green-500';
    if (score >= 0.5) return 'text-yellow-500';
    return 'text-red-500';
  };

  const getScoreBackground = (score: number) => {
    if (score >= 0.9) return 'bg-green-50';
    if (score >= 0.5) return 'bg-yellow-50';
    return 'bg-red-50';
  };

  const formatScore = (score: number) => Math.round(score * 100);

  const metrics = results ? [
    {
      name: 'Performance Score',
      value: formatScore(results.lighthouseResult.categories.performance.score),
      icon: Zap,
      description: 'Overall performance score based on Core Web Vitals'
    },
    {
      name: 'First Contentful Paint',
      value: results.lighthouseResult.audits['first-contentful-paint']?.displayValue,
      icon: Clock,
      description: 'Time until the first content is painted on the screen'
    },
    {
      name: 'Speed Index',
      value: results.lighthouseResult.audits['speed-index']?.displayValue,
      icon: BarChart2,
      description: 'How quickly content is visually displayed during page load'
    },
    {
      name: 'Time to Interactive',
      value: results.lighthouseResult.audits['interactive']?.displayValue,
      icon: Globe,
      description: 'Time until the page becomes fully interactive'
    }
  ] : [];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <div>
          <Button
            variant="ghost"
            onClick={() => navigate('/dashboard')}
            className="mb-4"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Dashboard
          </Button>
          <h1 className="text-2xl font-bold text-gray-900">Page Speed Insights</h1>
          <p className="text-gray-500 mt-2">
            Analyze your website's performance and get recommendations for improvement
          </p>
        </div>
      </div>

      {/* URL Input */}
      <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
        <div className="max-w-3xl mx-auto">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-1">
              <Input
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter website URL (e.g., example.com)"
                formatUrl
                className="w-full"
              />
            </div>
            <div className="flex gap-2">
              <Button
                variant={activeTab === 'mobile' ? 'default' : 'outline'}
                onClick={() => setActiveTab('mobile')}
                className="flex items-center"
              >
                <Smartphone className="h-4 w-4 mr-2" />
                Mobile
              </Button>
              <Button
                variant={activeTab === 'desktop' ? 'default' : 'outline'}
                onClick={() => setActiveTab('desktop')}
                className="flex items-center"
              >
                <Desktop className="h-4 w-4 mr-2" />
                Desktop
              </Button>
            </div>
            <Button
              onClick={analyzeUrl}
              disabled={loading || !url}
              className="flex items-center"
            >
              {loading ? (
                <>
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                  Analyzing...
                </>
              ) : (
                <>
                  <Zap className="h-4 w-4 mr-2" />
                  Analyze
                </>
              )}
            </Button>
          </div>

          {error && (
            <div className="mt-4 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded flex items-center">
              <AlertTriangle className="h-5 w-5 mr-2" />
              {error}
            </div>
          )}
        </div>
      </div>

      {/* Results */}
      {results && (
        <div className="space-y-8">
          {/* Metrics Overview */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {metrics.map((metric, index) => {
              const Icon = metric.icon;
              return (
                <div key={index} className="bg-white rounded-lg shadow-lg p-6">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center">
                      <Icon className="h-6 w-6 text-blue-500" />
                      <h3 className="ml-2 text-sm font-medium text-gray-900">{metric.name}</h3>
                    </div>
                    <div className={`text-2xl font-bold ${typeof metric.value === 'number' ? getScoreColor(metric.value / 100) : ''}`}>
                      {metric.value}
                    </div>
                  </div>
                  <p className="text-sm text-gray-500">{metric.description}</p>
                </div>
              );
            })}
          </div>

          {/* Detailed Audits */}
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-6">Performance Audits</h2>
            <div className="space-y-6">
              {Object.entries(results.lighthouseResult.audits)
                .filter(([_, audit]) => audit.score !== null && typeof audit.score !== 'undefined')
                .map(([id, audit]) => (
                  <div key={id} className={`${getScoreBackground(audit.score)} rounded-lg p-4`}>
                    <div className="flex items-center justify-between mb-2">
                      <h3 className="text-sm font-medium text-gray-900">{audit.title}</h3>
                      <span className={`text-sm font-semibold ${getScoreColor(audit.score)}`}>
                        {formatScore(audit.score)}%
                      </span>
                    </div>
                    <p className="text-sm text-gray-600">{audit.description}</p>
                    {audit.displayValue && (
                      <p className="mt-2 text-sm font-medium text-gray-900">
                        Value: {audit.displayValue}
                      </p>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageSpeedInsights;