import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatUrl(url: string): string | null {
  if (!url) return null;
  
  // Remove leading/trailing whitespace
  url = url.trim();
  
  try {
    // Parse and validate URL
    const urlObj = new URL(
      url.startsWith('http://') || url.startsWith('https://')
        ? url
        : `https://${url}`
    );
    
    // Always force HTTPS
    urlObj.protocol = 'https:';
    
    // Return normalized URL
    return urlObj.toString();
  } catch {
    return null;
  }
}

export function getDomainFromUrl(url: string): string | null {
  try {
    const formattedUrl = formatUrl(url);
    if (!formattedUrl) return null;
    return new URL(formattedUrl).hostname;
  } catch {
    return null;
  }
}

export function isValidUrl(url: string): boolean {
  return formatUrl(url) !== null;
}

export async function getApiKey(supabase: any, serviceName: string): Promise<string | null> {
  try {
    const { data, error } = await supabase
      .from('api_keys')
      .select('api_key')
      .eq('service_name', serviceName)
      .eq('is_active', true)
      .single();

    if (error) {
      console.error('Error fetching API key:', error);
      return null;
    }

    // Return the API key directly since we're selecting the api_key column
    return data?.api_key || null;
  } catch (error) {
    console.error('Error getting API key:', error);
    return null;
  }
}